import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResourceOnce} from '@lookout/suspense'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readAccount, readFirstDevice} from './account-details-service'
import SummaryResult from './summary-result'

const AccountSummary = ({className}) => {
  const {accountGuid} = useParams()

  const accountInfo = useResourceOnce(() => readAccount({guid: accountGuid}))
  const firstDeviceInfo = useResourceOnce(() =>
    readFirstDevice({query: {accountGuid}})
  )

  return (
    <div
      className={classes('summary-layout', className)}
      css={{padding: '20px 0', margin: 0}}
    >
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="summary-results-error"
            message={error.body?.message || I18n.t('errors.service.accounts')}
          />
        )}
      >
        <Suspense
          fallback={<Fallback className="summary-results-pending" spinner />}
        >
          <SummaryResult
            accountInfo={accountInfo}
            firstDeviceInfo={firstDeviceInfo}
          />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default AccountSummary
