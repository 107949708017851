import {ErrorBoundary} from 'react-error-boundary'
import Fallback from '../errors/fallback'
import DeviceEventLogsDetails from './device-event-logs-details'

const DeviceEventLogs = () => (
  <ErrorBoundary
    fallback={
      <Fallback
        className="device-unhandled-error"
        message={I18n.t('errors.generic.message')}
      />
    }
  >
    <DeviceEventLogsDetails />
  </ErrorBoundary>
)

export default DeviceEventLogs
