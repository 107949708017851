import request from '../../lib/api/request'
import apiUrlFor from '../../lib/utils/api-url-for'
import {stringifyQuery} from '../../lib/utils/query-params'
import {handle403, handle404} from '../errors/service-error-helper'

export const readRegistrarDevices = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/registrar/public/devices?${stringifyQuery({email: query.q})}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {devices: []}})
  }
}

export const readCashierAccounts = async ({query, ...opts}) => {
  try {
    return [await request(apiUrlFor(`/cashier/accounts/${query.q}`), opts)]
  } catch (error) {
    return handle404({error, response: []})
  }
}

export const readDevicesGuid = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/registrar/public/device/account?${stringifyQuery({
          deviceGuid: query.q,
        })}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {devices: []}})
  }
}

export const readRegistrarAccountDevice = async ({query, ...opts}) => {
  try {
    const deviceGuid = await readDevicesGuid({query, ...opts})
    return deviceGuid
      ? await request(apiUrlFor(`/cashier/accounts/${deviceGuid}`))
      : {}
  } catch (error) {
    return {}
  }
}

const registrarAdapter = device => ({
  deviceGuid: device.deviceGuid,
  email: device.metadata?.email,
  accountGuid: device.accountGuid,
})

const cashierAdapter = account => ({
  accountGuid: account.guid,
  product: account.product,
})

const deviceAdapter = device => ({
  deviceGuid: device.deviceGuid,
  accountGuid: device.guid,
})

export const readAccounts = async opts => {
  const [{devices: registrarDevices}, cashierAccounts, device] =
    await Promise.all([
      readRegistrarDevices(opts),
      readCashierAccounts(opts),
      readRegistrarAccountDevice(opts),
    ])

  return _.union(
    _.map(registrarDevices, registrarAdapter),
    _.map(cashierAccounts, cashierAdapter),
    _.map(
      _.isEmpty(device) ? [] : [{...device, deviceGuid: opts.query?.q}],
      deviceAdapter
    )
  )
}
