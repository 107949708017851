import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readChangeLogs} from './account-details-service'
import ChangeLogsTable from './change-logs-table'

const ChangeLogs = () => {
  const {accountGuid} = useParams()
  const changeLogs = useResourceOnce(() => readChangeLogs({accountGuid}))

  return (
    <div className="change-logs-layout">
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="change-logs-results-error"
            message={error.body?.message || I18n.t('errors.service.accounts')}
          />
        )}
      >
        <Suspense
          fallback={<Fallback className="change-logs-pending" spinner />}
        >
          <ChangeLogsTable changeLogs={changeLogs} />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default ChangeLogs
