import {useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'

export const stringifyQuery = (
  params = {},
  replacer,
  encodeFn = encodeURIComponent
) => {
  if (!_.isFunction(replacer)) {
    replacer = (key, value) =>
      value != null
        ? _.isArray(value)
          ? value.map(v => ({key, value: v}))
          : {key, value}
        : null
  }
  return Object.keys(params)
    .reduce((pairs, key) => pairs.concat(replacer(key, params[key]) || []), [])
    .map(({key, value}) => [key, value].map(encodeFn).join('='))
    .join('&')
}

export const parseQuery = (string = '', reviver) => {
  if (!_.isFunction(reviver)) {
    reviver = (key, value) => ({key, value})
  }
  string = string.slice(1 + string.indexOf('?'))
  return string.split('&').reduce((params, pair) => {
    const parts = pair.split('=').map(decodeURIComponent)
    const {key, value} = reviver(...parts) || {}
    if (value != null) {
      // eslint-disable-next-line no-prototype-builtins
      params[key] = params.hasOwnProperty(key)
        ? [].concat(params[key], value)
        : value
    }
    return params
  }, {})
}

export const useRouteQuery = () => {
  const [params] = useSearchParams()
  return useMemo(() => parseQuery(params.toString()), [params])
}

export const toPlainObject = searchParams =>
  _.reduce(
    Array.from(searchParams.entries()),
    (result, [key, value]) => ({...result, [key]: value}),
    {}
  )
