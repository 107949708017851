import {getLocation} from '../utils/location-proxy'
import {stringifyQuery} from '../utils/query-params'

/**
 * Returns url query params string with BP destination settings.
 */
const destinationParams = ({withId = true, withPath = true} = {}) => {
  const location = getLocation()
  let destinationId
  let destinationPath
  if (withId) destinationId = global.config.destination_id
  if (withPath) {
    const path = location.pathname + location.search
    destinationPath = path !== '/' ? path : undefined
  }
  return stringifyQuery({destinationId, destinationPath})
}

export default destinationParams
