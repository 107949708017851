export const keyName = ({deviceGuid}) => `log-id--${deviceGuid}`

export const getLogId = ({deviceGuid}) => {
  const logIdDetails = JSON.parse(
    global.localStorage.getItem(keyName({deviceGuid}))
  )
  // check for validity of storedLog
  const {logId, createdAt} = logIdDetails || {}
  if (!logId || !createdAt) return {}

  const timeDiff = Math.round((new Date() - new Date(createdAt)) / 60000)
  /// if storedLogId existing and older than 30minute
  if (timeDiff >= 30) return {}

  return logIdDetails
}

export const writeStore = ({deviceGuid, value}) =>
  global.localStorage.setItem(keyName({deviceGuid}), JSON.stringify(value))

export const setLogId = ({id, deviceGuid}) =>
  writeStore({deviceGuid, value: {logId: id, createdAt: new Date()}})

export const updateLogId = ({deviceGuid, ...props}) => {
  const storedLogId = getLogId({deviceGuid})
  if (_.isEmpty(storedLogId)) return null
  writeStore({deviceGuid, value: {...storedLogId, ...props}})
}
