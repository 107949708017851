import {Suspense, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useResource} from '@lookout/suspense'
import {Slide, Layout, Panel, PanelHeader} from '@lookout/ui'
import {breakpoints} from '@lookout/design'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import Fallback from '../errors/fallback'
import DevicePage from '../device/device-page'
import {readDeviceDetails} from '../device/device-service'
import DeviceActions from '../device/device-actions'

const {aboveQuery, screenSmMin} = breakpoints

export const DeviceTitle = ({device, ...props}) => {
  const [{software = {}, hardware = {}}] = device.value()
  const deviceName = software.device_name || ''
  const deviceModel = hardware.model || ''
  const name = deviceName
    ? deviceName +
      (deviceModel && deviceModel !== deviceName ? ` (${deviceModel})` : '')
    : deviceModel

  return (
    <Layout css={{justifyContent: 'space-between'}} horizontal>
      <h1 className="device-name" {...props}>
        {name || I18n.t('device.title')}
      </h1>
      <DeviceActions />
    </Layout>
  )
}

const DeviceSlidePanel = () => {
  const {accountGuid} = useParams()
  const {deviceGuid} = useParams()
  const navigate = useNavigate()
  const accountDetailsPath = `/account/${accountGuid}`
  const [device, isPending, startReadDeviceDetails] =
    useResource(readDeviceDetails)

  useEffect(() => {
    startReadDeviceDetails({deviceGuid})
  }, [startReadDeviceDetails, deviceGuid])

  return (
    <Slide
      className="device-slide-panel"
      css={{[aboveQuery({min: screenSmMin})]: {width: '50%'}}}
      onClosed={() => navigate(accountDetailsPath)}
    >
      {({closeSlide}) => (
        <Panel onClose={() => closeSlide()}>
          <PanelHeader>
            {device ? (
              <NetworkErrorBoundary
                resetKeys={[deviceGuid]}
                fallback={
                  <Fallback
                    className="device-title-results-error"
                    css={{minHeight: 0, justifyContent: 'start'}}
                    message={I18n.t('device.title')}
                  />
                }
              >
                <Suspense
                  fallback={
                    <Fallback
                      className="device-title-pending"
                      css={{minHeight: 0, justifyContent: 'start'}}
                      message={I18n.t('loading.text')}
                    />
                  }
                >
                  <DeviceTitle device={device} />
                </Suspense>
              </NetworkErrorBoundary>
            ) : (
              I18n.t('device.title')
            )}
          </PanelHeader>
          {device && (
            <NetworkErrorBoundary
              resetKeys={[deviceGuid]}
              fallback={
                <Fallback
                  className="device-info-results-error"
                  message={I18n.t('errors.service.devices')}
                />
              }
            >
              <Suspense
                fallback={<Fallback className="device-info-pending" spinner />}
              >
                <DevicePage
                  isPending={isPending}
                  device={device}
                  css={{
                    marginLeft: -40,
                    marginRight: -40,
                    marginBottom: 50,
                    height: '100%',
                    overflow: 'auto',
                  }}
                />
              </Suspense>
            </NetworkErrorBoundary>
          )}
        </Panel>
      )}
    </Slide>
  )
}

export default DeviceSlidePanel
