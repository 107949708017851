import {isAbsoluteUrl} from './absolute-url'
import urlJoin from './url-join'

const serverUrlFor = path =>
  isAbsoluteUrl(path)
    ? path
    : // If `config.server` is unset app's domain is same to server's.
      // Enforce the combined path to start with `/` to make sure it's not
      // relative to current app's route
      urlJoin(global.config.server || '/', path)

export default serverUrlFor
