import {getYear, parseISO, format} from 'date-fns'

const parseAndFormatDate = (value, dateFormat) => {
  if (!value) return null
  const isoDate = parseISO(value)
  const year = getYear(isoDate)
  if (!year) return I18n.t('account_details.invalid_date')
  const isNever = year > 2100
  return isNever
    ? I18n.t('account_details.infinite_date')
    : format(isoDate, dateFormat)
}

export default parseAndFormatDate
