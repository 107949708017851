import {useEffect, Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResource} from '@lookout/suspense'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import Fallback from '../errors/fallback'
import {readDeviceEventLogs} from './device-service'
import DeviceEventLogsTable from './device-event-logs-table'

const DeviceEventLogsDetails = () => {
  const {deviceGuid} = useParams()
  const [deviceEventLogs, , startReadDeviceEventLogs] =
    useResource(readDeviceEventLogs)

  useEffect(() => {
    startReadDeviceEventLogs({deviceGuid})
  }, [startReadDeviceEventLogs, deviceGuid])

  return (
    deviceEventLogs && (
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="device-event-logs-results-error"
            message={error.body?.message || I18n.t('errors.service.devices')}
          />
        )}
      >
        <Suspense
          fallback={<Fallback className="device-event-logs-pending" spinner />}
        >
          <DeviceEventLogsTable deviceEventLogs={deviceEventLogs} />
        </Suspense>
      </NetworkErrorBoundary>
    )
  )
}

export default DeviceEventLogsDetails
