import {useMemo, useCallback} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {FilterSelect} from '@lookout/ui'
import {stringifyQuery, toPlainObject} from '../../lib/utils/query-params'

const accountSearchFilterStyle = {
  dropdownIndicator: () => ({
    display: 'none',
  }),
}

const AccountSearch = () => {
  const newOptionData = useMemo(
    () => ({
      groupId: 'q',
      groupName: I18n.t('account.filter.group_name'),
      description: I18n.t('account.filter.description'),
    }),
    []
  )
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const query = useMemo(() => toPlainObject(searchParams), [searchParams])
  const filterValue = useMemo(
    () => FilterSelect.deserialize(query, {newOptionData}),
    [newOptionData, query]
  )

  const onFilterChange = useCallback(
    val => {
      const obj = FilterSelect.serialize(val, {newOptionData})

      const queryStr = stringifyQuery(obj)
      navigate(queryStr ? `?${queryStr}` : '')
    },
    [navigate, newOptionData]
  )

  return (
    <div className="account-search">
      <FilterSelect
        autoFocus
        className="account-filter-select"
        placeholder={I18n.t('account.filter.placeholder')}
        getNewOptionData={() => newOptionData}
        options={[]}
        onChange={onFilterChange}
        value={filterValue}
        css={{marginBottom: 20}}
        styles={accountSearchFilterStyle}
      />
    </div>
  )
}

export default AccountSearch
