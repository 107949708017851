import {useState, useMemo, useCallback} from 'react'
import parseDate from 'date-fns/parse'
import {Table, tableStyles, TableSortingHeader, typography} from '@lookout/ui'
import Fallback from '../errors/fallback'
import parseAndFormatDate from '../utils/format-date-time'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import classes from '../../lib/utils/classes'

const {blush} = typography

const dateSorter = value =>
  _.isObject(value) ? parseDate(`${value.date} ${value.time}`) : value

const StateHistoryResult = ({history}) => {
  const data = history.value()

  const [sortingOption, setSortingOption] = useState({
    sort_by: 'Created on',
    order: 'asc',
  })

  const resolveCurrentOrder = useCallback(
    columnName =>
      sortingOption.sort_by === columnName ? sortingOption.order : 'none',
    [sortingOption]
  )

  const setSortingOptionBy = useCallback(
    columnName => {
      setSortingOption({
        sort_by: columnName,
        order: sortingOption.order === 'asc' ? 'desc' : 'asc',
      })
    },
    [sortingOption]
  )

  const orderedData = useMemo(
    () =>
      _.orderBy(
        data,
        [d => dateSorter(d[sortingOption.sort_by])],
        [sortingOption.order]
      ),
    [sortingOption.sort_by, sortingOption.order, data]
  )

  return _.size(orderedData) > 0 ? (
    <Table
      className="state-history-table"
      css={[
        tableStyles.default,
        tableStyles.compact,
        tableLayoutStyles.fullWidth,
        {marginTop: 10},
      ]}
    >
      <thead>
        <tr>
          <th>{I18n.t('account_details.state_history.table.state')}</th>
          <TableSortingHeader
            className="start-header"
            title={I18n.t('account_details.state_history.table.state_start')}
            sortOrder={resolveCurrentOrder('created_at')}
            onClick={() => setSortingOptionBy('created_at')}
          />
          <TableSortingHeader
            className="end-header"
            title={I18n.t('account_details.state_history.table.state_end')}
            sortOrder={resolveCurrentOrder('updated_at')}
            onClick={() => setSortingOptionBy('updated_at')}
          />
          <TableSortingHeader
            className="expiry-header"
            title={I18n.t('account_details.state_history.table.state_expiry')}
            sortOrder={resolveCurrentOrder('expires-on')}
            onClick={() => setSortingOptionBy('expires-on')}
          />
          <th>{I18n.t('account_details.state_history.table.tier')}</th>
        </tr>
      </thead>
      <tbody>
        {orderedData.map(d => (
          <tr
            className={classes('state-history-row', `history-row-${d.id}`)}
            key={d.id}
          >
            <td className="state">{d.state}</td>
            <td className="created" css={blush}>
              {parseAndFormatDate(d.created_at, 'yyyy-MM-dd h:mmaaa')}
            </td>
            <td className="updated" css={blush}>
              {parseAndFormatDate(d.updated_at, 'yyyy-MM-dd h:mmaaa')}
            </td>
            <td className="expires" css={blush}>
              {parseAndFormatDate(d.state_expiry, 'yyyy-MM-dd h:mmaaa')}
            </td>
            <td className="tier" css={blush}>
              {d.tier}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <Fallback
      className="history-results-empty"
      message={I18n.t('account_details.state_history.table.empty.query')}
    />
  )
}

export default StateHistoryResult
