import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readUserInfo} from './account-details-service'
import UserInfoDetails from './user-info-details'

const UserInfo = () => {
  const {accountGuid} = useParams()
  const usersInfo = useResourceOnce(() => readUserInfo({query: {accountGuid}}))

  return (
    <div className="user-info-layout">
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="user-info-results-error"
            message={error.body?.message || I18n.t('errors.service.accounts')}
          />
        )}
      >
        <Suspense
          fallback={<Fallback className="user-info-results-pending" spinner />}
        >
          <UserInfoDetails usersInfo={usersInfo} />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default UserInfo
