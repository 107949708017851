import {colors, typography, Layout, SmallOutlineButton} from '@lookout/ui'
import logoHorizontalCentered from '../../assets/images/logo-horizontal-centered.svg'

const styles = {
  overlay: {
    backgroundColor: colors.white,
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1e4,
  },

  container: {
    margin: '60px auto',
    padding: '40px',

    a: {
      whiteSpace: 'nowrap',
    },
  },

  logo: {
    alignSelf: 'center',
    width: 210,
    marginBottom: 50,
  },

  message: {
    fontSize: typography.toRem(30),
  },
}

const FullScreenErrorMessage = ({
  className,
  message,
  description,
  resetErrorBoundary,
}) => (
  <Layout
    className={className}
    css={[{justifyContent: 'center'}, styles.overlay]}
    horizontal
  >
    <Layout vertical css={styles.container}>
      <img
        src={logoHorizontalCentered}
        css={styles.logo}
        alt={I18n.t('brand.lookout')}
      />
      <Layout vertical>
        <h1 className="error-message" css={styles.message}>
          {message}
        </h1>
        {description && <p className="error-description">{description}</p>}
        <Layout horizontal>
          <p>
            {resetErrorBoundary && (
              <SmallOutlineButton
                className="reset-button"
                css={{marginRight: 20}}
                onClick={() => resetErrorBoundary()}
              >
                {I18n.t('errors.generic.try_again')}
              </SmallOutlineButton>
            )}
          </p>
        </Layout>
      </Layout>
    </Layout>
  </Layout>
)

export default FullScreenErrorMessage
