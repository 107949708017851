import {Table, tableStyles, typography} from '@lookout/ui'
import Fallback from '../errors/fallback'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import formatDate from '../../lib/layout/format-date'

const {blush} = typography

const capitalize = str => _.capitalize(_.replace(str, /(_)/g, ' '))

const EntitlementResult = ({entitlement}) => {
  const {accountState, features} = entitlement.value()

  return (
    <div className="entitlement-result">
      {accountState && (
        <Table
          className="entitlement-table"
          css={[
            tableStyles.default,
            tableLayoutStyles.fullWidth,
            {
              marginBottom: 40,
            },
          ]}
        >
          <tbody>
            {_.map(_.keys(accountState), key => (
              <tr className={`entitlement-row-${_.kebabCase(key)}`} key={key}>
                <td className="entitlement-key" css={{minWidth: 200}}>
                  {capitalize(key)}
                </td>
                <td className="entitlement-value" css={blush}>
                  {key === 'timestamp' || key === 'expiration'
                    ? formatDate(accountState[key], 'yyyy-MM-dd h:mmaaa')
                    : accountState[key]}
                </td>
              </tr>
            ))}
            <tr className="features-row">
              <td className="features-title">Features</td>
              <td className="features-value">
                {_.map(features, feature => (
                  <span
                    key={feature}
                    className={`features-list-item-${_.kebabCase(feature)}`}
                    css={{marginRight: 10}}
                  >
                    {capitalize(feature)},
                  </span>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      {!accountState && !features && (
        <Fallback
          className="entitlement-results-empty"
          message={I18n.t('account_details.entitlement.empty.query')}
        />
      )}
    </div>
  )
}

export default EntitlementResult
