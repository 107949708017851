import {useEffect, useRef} from 'react'
import {Layout, Progress} from '@lookout/ui'
import DeviceElkLogDownload from './device-elk-log-download'
import {updateLogId} from './log-id-store'

const DeviceElkLogIdDetails = ({
  deviceGuid,
  onLogStatusRefresh,
  logStatus,
  logId,
}) => {
  // Read the first download status
  const {percentage, processed_service_count, status} = logStatus.value()

  const intervalRef = useRef()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      onLogStatusRefresh({logId})
    }, 5000)
    return () => clearInterval(intervalRef.current)
  }, [logId, onLogStatusRefresh])

  useEffect(() => {
    if (percentage === 100) {
      clearInterval(intervalRef.current)
    }
  }, [percentage])
  // update downloading status in the local storage
  useEffect(() => {
    updateLogId({status, deviceGuid})
  }, [deviceGuid, status])

  return percentage < 100 ? (
    <div
      className="device-elk-log-id-details-layout"
      css={{maxWidth: 500, width: '50vw'}}
    >
      <Progress
        className="device-elk-download-progress"
        {...{
          value: percentage || 0,
          max: 100,
          css: {width: '100%'},
        }}
      />
      <Layout
        horizontal
        className="device-elk-download-service-count"
        css={{alignItems: 'center', justifyContent: 'center', minHeight: 60}}
      >
        {I18n.t('device.elk_processed_services', {
          count: processed_service_count,
        })}
      </Layout>
    </div>
  ) : (
    <DeviceElkLogDownload logId={logId} />
  )
}

export default DeviceElkLogIdDetails
