import {Suspense} from 'react'
import {
  Modal,
  Panel,
  PanelHeader,
  PanelDescription,
  PanelFooter,
  LinkButton,
  Button,
} from '@lookout/ui'
import {useParams} from 'react-router-dom'
import {useResource, ResourceSuccess} from '@lookout/suspense'
import {ErrorBoundary} from 'react-error-boundary'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import Fallback from '../errors/fallback'
import {createVpnProfiles} from './account-details-service'

const AddDevicesVpnProfileModal = ({deviceGuids, onClosed}) => {
  const [addVpnProfilesResource, isAddVpnPending, startAddVpnProfiles] =
    useResource(createVpnProfiles)
  const {accountGuid} = useParams()

  return (
    <Modal
      className="add-device-vpn-profile-modal"
      boundary="viewport"
      onClosed={onClosed}
    >
      {({closeModal}) => (
        <Panel onClose={() => closeModal()}>
          {({notifyError}) => (
            <>
              <PanelHeader>
                {I18n.t(
                  'account_details.devices.actions.add_vpn_profile.modal.title'
                )}
              </PanelHeader>
              <ErrorBoundary
                fallback={
                  <Fallback
                    className="add-vpn-profile-unhandled-error"
                    message={I18n.t('errors.generic.message')}
                  />
                }
              >
                {addVpnProfilesResource && (
                  <NetworkErrorBoundary
                    resetKeys={[addVpnProfilesResource]}
                    onError={error =>
                      notifyError(
                        error.body.error || I18n.t('errors.service.vpn')
                      )
                    }
                  >
                    <Suspense>
                      <ResourceSuccess
                        resource={addVpnProfilesResource}
                        onSuccess={closeModal}
                      />
                    </Suspense>
                  </NetworkErrorBoundary>
                )}
              </ErrorBoundary>
              <PanelDescription>
                {I18n.t(
                  'account_details.devices.actions.add_vpn_profile.modal.description'
                )}
              </PanelDescription>
              <PanelFooter>
                <LinkButton
                  type="button"
                  className="cancel-button"
                  onClick={() => closeModal()}
                >
                  {I18n.t('buttons.cancel')}
                </LinkButton>
                <Button
                  autoFocus
                  className="confirm-button"
                  type="button"
                  disabled={isAddVpnPending}
                  onClick={() =>
                    startAddVpnProfiles({deviceGuids, accountGuid})
                  }
                >
                  {isAddVpnPending
                    ? I18n.t('buttons.adding')
                    : I18n.t('buttons.add')}
                </Button>
              </PanelFooter>
            </>
          )}
        </Panel>
      )}
    </Modal>
  )
}

export default AddDevicesVpnProfileModal
