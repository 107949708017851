import {Suspense} from 'react'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readVPNProvider} from './device-service'
import VPNProviderDetails from './vpn-provider-details'

const VPNProvider = ({url}) => {
  const vpnProvider = useResourceOnce(() => readVPNProvider({url}))
  return (
    <div className="device-vpn-provider-section">
      <NetworkErrorBoundary
        fallback={
          <Fallback
            className="device-vpn-provider-results-error"
            message={I18n.t('errors.service.devices')}
          />
        }
      >
        <Suspense
          fallback={
            <Fallback className="device-vpn-provider-results-pending" spinner />
          }
        >
          <VPNProviderDetails vpnProvider={vpnProvider} />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default VPNProvider
