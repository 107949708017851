import {useEffect, Suspense} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import {Layout} from '@lookout/ui'
import Fallback from '../../errors/fallback'
import {readDeviceElkLogStatus} from '../device-service'
import DeviceElkLogIdDetails from './device-elk-log-id-details'

const DeviceElkLogPolling = ({logId}) => {
  const {deviceGuid} = useParams()
  const [logStatus, , startReadLogStatus] = useResource(readDeviceElkLogStatus)

  useEffect(() => {
    startReadLogStatus({logId})
  }, [logId, startReadLogStatus])

  return (
    <Layout
      vertical
      className="device-elk-log-polling-layout"
      css={{alignItems: 'stretch', justifyContent: 'center'}}
    >
      {logStatus ? (
        <Suspense
          fallback={
            <Fallback
              className="device-elk-downloading-status-pending"
              spinner
            />
          }
        >
          <DeviceElkLogIdDetails
            deviceGuid={deviceGuid}
            logId={logId}
            logStatus={logStatus}
            onLogStatusRefresh={({logId: id}) =>
              startReadLogStatus({logId: id})
            }
          />
        </Suspense>
      ) : null}
    </Layout>
  )
}

export default DeviceElkLogPolling
