import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readAccountEntitler} from './account-details-service'
import EntitlementResult from './entitlement-result'

const Entitlement = () => {
  const {accountGuid} = useParams()
  const entitlement = useResourceOnce(() =>
    readAccountEntitler({query: {accountGuid}})
  )

  return (
    <div className="entitlement-layout">
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="entitlement-results-error"
            message={
              error.body?.message ||
              error.body?.content ||
              I18n.t('errors.service.accounts')
            }
          />
        )}
      >
        <Suspense
          fallback={
            <Fallback className="entitlement-results-pending" spinner />
          }
        >
          <EntitlementResult entitlement={entitlement} />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default Entitlement
