import React, {useState} from 'react'
import {Table, tableStyles, typography} from '@lookout/ui'
import Fallback from '../errors/fallback'
import parseAndFormatDate from '../utils/format-date-time'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import classes from '../../lib/utils/classes'
import TupleItem from '../utils/tuple-item'

const {blush} = typography

const ExpandedRow = ({breach}) =>
  _.map(breach, (value, key) => (
    <TupleItem
      className="breach-details-row"
      key={key}
      name={key}
      value={value}
    />
  ))

const BreachDetails = ({breachesInfo}) => {
  const [expandedUser, setExpandedUser] = useState(null)
  const {breaches} = breachesInfo.value()

  return (
    <div className="breaches-result">
      {!_.isEmpty(breaches) ? (
        <Table
          className="breaches-table"
          css={[
            tableStyles.striped,
            tableStyles.cursorPointer,
            tableLayoutStyles.fullWidth,
            tableLayoutStyles.expandable,
            {
              marginBottom: 40,
            },
          ]}
        >
          <thead>
            <tr>
              <th>{I18n.t('account_details.breaches.table.title')}</th>
              <th>{I18n.t('account_details.breaches.table.type')}</th>
              <th>{I18n.t('account_details.breaches.table.alert_date')}</th>
              <th>
                {I18n.t('account_details.breaches.table.acquisition_date')}
              </th>
              <th>{I18n.t('account_details.breaches.table.breach_date')}</th>
              <th>{I18n.t('account_details.breaches.table.severity')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(breaches, breachRec => {
              const isExpanded = breachRec.guid === expandedUser
              return (
                <React.Fragment key={breachRec.guid}>
                  <tr
                    className={classes(
                      'breaches-table-row',
                      `breach-${breachRec.guid}`,
                      'expandable',
                      isExpanded && 'expanded'
                    )}
                    onClick={() =>
                      setExpandedUser(isExpanded ? null : breachRec.guid)
                    }
                  >
                    <td className="title">{breachRec.title}</td>
                    <td className="type">{breachRec.typeOfBreach}</td>
                    <td className="alert-date" css={blush}>
                      {breachRec.breachAlertDate}
                    </td>
                    <td className="acquisition-data" css={blush}>
                      {parseAndFormatDate(
                        breachRec.acquisitionDate,
                        'yyyy-MM-dd h:mmaaa'
                      )}
                    </td>
                    <td className="breach-date" css={blush}>
                      {parseAndFormatDate(
                        breachRec.breachDate,
                        'yyyy-MM-dd h:mmaaa'
                      )}
                    </td>
                    <td className="severity">{breachRec.severity}</td>
                  </tr>
                  {isExpanded && (
                    <tr
                      className={classes(
                        `breach-details-row-${breachRec.guid}`,
                        'expanded-row'
                      )}
                    >
                      <td colSpan={999}>
                        <ExpandedRow breach={breachRec} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <Fallback
          className="breach-results-empty"
          message={I18n.t('account_details.breaches.table.empty.query')}
        />
      )}
    </div>
  )
}

export default BreachDetails
