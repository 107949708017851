import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {Layout, useLoading, typography} from '@lookout/ui'
import classes from '../../lib/utils/classes'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

const {blush} = typography

const DeviceHeaderDetails = ({
  className,
  registrarDeviceInfo,
  cdsDeviceInfo,
  isPending,
}) => {
  const {deviceGuid} = useParams()
  const {header: {affinities = []} = {}, device_checkin = {}} =
    cdsDeviceInfo || {}

  const {
    locale,
    timezone,
    deviceOS,
    last_ip,
    preloaded,
    ctn_id,
    phone_num,
    email,
  } = registrarDeviceInfo || {}

  const affinityWithEnterprise = useMemo(
    () =>
      _.head(
        _.filter(
          affinities,
          affinity => _.head(affinity.split('/')) === 'enterprise'
        )
      ),
    [affinities]
  )

  const entGuid = useMemo(
    () => _.nth(affinityWithEnterprise?.split('/'), 1),
    [affinityWithEnterprise]
  )

  const {elementRef} = useLoading(isPending)

  return (
    <Layout
      className={classes('device-header-layout', className)}
      vertical
      css={containerStyle}
      ref={elementRef}
    >
      <TupleItem
        className="device-header-item"
        name={I18n.t('device.guid')}
        value={deviceGuid}
      />
      <TupleItem
        className="device-header-item"
        name={I18n.t('device.ent_guid')}
        value={entGuid}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.email')}
        value={email}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.locale')}
        value={locale}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.timezone')}
        value={timezone}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.device_os')}
        value={deviceOS}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.last_ip')}
        value={last_ip}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.last_connected')}
        value={device_checkin.device_timestamp}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t('device.preloaded')}
        value={preloaded}
      />
      <TupleItem
        className="device-header-item"
        css={blush}
        name={I18n.t(`device.${phone_num ? 'phone_num' : 'ctn_id'}`)}
        value={phone_num || ctn_id}
      />
    </Layout>
  )
}

export default DeviceHeaderDetails
