import 'core-js/stable'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Global, css} from '@emotion/react'
import {globalStyles} from '@lookout/ui'
import '../config/locales/console/en'
import AppInit from './app-init'
import './favicons'
/* eslint-disable import/order, spaced-comment */
///////////////
/////////////////////////////////////////
//////////
/* eslint-enable spaced-comment */

/* eslint-disable spaced-comment */
///////////////
/////////////////////
///////////////////////////////////
////////////////////////////////////
//////////////
///////////////////
////////////////////////
//////////////
//////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
/////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
///////////////////
///////////
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
//////////
////////
//////
////
 
//////////
/* eslint-enable spaced-comment */

createRoot(global.document.getElementById('content')).render(
  <>
    <Global styles={globalStyles} />
    <Global
      styles={css(import('modern-normalize/modern-normalize.css').toString())}
    />
    <Global
      styles={css(
        import('@lookout/fonts/noto-sans/noto-sans-400.css').toString()
      )}
    />
    <Global
      styles={css(
        import('@lookout/fonts/noto-sans/noto-sans-700.css').toString()
      )}
    />
    <BrowserRouter basename={global.config.url_root}>
      <AppInit />
    </BrowserRouter>
  </>
)
