import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readStateHistory} from './account-details-service'
import StateHistoryResult from './state-histories-result'

const StateHistory = () => {
  const {accountGuid} = useParams()
  const history = useResourceOnce(() => readStateHistory({guid: accountGuid}))

  return (
    <div className="state-history-layout">
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="state-history-results-error"
            message={error.body?.message || I18n.t('errors.service.accounts')}
          />
        )}
      >
        <Suspense
          fallback={
            <Fallback className="state-history-results-pending" spinner />
          }
        >
          <StateHistoryResult history={history} />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default StateHistory
