import {useMemo, useCallback, useState} from 'react'
import {useLocation, Link} from 'react-router-dom'
import {
  Table,
  tableStyles,
  typography,
  Layout,
  IconButton,
  Tooltip,
  colors,
  Pager as LookoutPager,
  prevPageQuery,
  nextPageQuery,
} from '@lookout/ui'
import {MiscApps, RichEditorUnorderedList} from '@lookout/dingbats'
import {stringifyQuery, useRouteQuery} from '../../../lib/utils/query-params'
import Fallback from '../../errors/fallback'
import parseAndFormatDate from '../../utils/format-date-time'
import TupleItem from '../../utils/tuple-item'

const {blush} = typography

const logTableStyle = {
  ...tableStyles.default,
  ...tableStyles.compact,
  ...tableStyles.striped,
  marginTop: 10,
  tbody: {
    td: {
      verticalAlign: 'top !important',
      ...typography.breakText,
      ...blush,
    },
  },
}

const selectedIconStyle = {
  border: `1px solid ${colors.lookout}`,
  fill: colors.lookout,
}

export const defaultQuery = {
  page: 1,
  per_page: 100,
}

const Pager = ({isPending, pagination: {total, has_previous, has_next}}) => {
  const location = useLocation()
  const routeQuery = useRouteQuery()

  const stats = useMemo(
    () => ({
      ...LookoutPager.stats({query: routeQuery, defaultQuery, total}),
      total,
    }),
    [routeQuery, total]
  )

  const prevSearch = _.thru(
    prevPageQuery({query: routeQuery, defaultQuery, total}),
    q => (q ? stringifyQuery({...routeQuery, ...q}) : undefined)
  )
  const nextSearch = _.thru(
    nextPageQuery({query: routeQuery, defaultQuery, total}),
    q => (q ? stringifyQuery({...routeQuery, ...q}) : undefined)
  )

  return (
    <LookoutPager
      className="device-elk-table-pager"
      pagerButtonAs={Link}
      prevPageProps={{
        to: `?${prevSearch}`,
        disabled: isPending || !has_previous,
        state: location.search,
      }}
      nextPageProps={{
        to: `?${nextSearch}`,
        disabled: isPending || !has_next,
        state: location.search,
      }}
      label={
        isPending
          ? _.isNil(total)
            ? I18n.t('loading.text')
            : I18n.t('items.loading_total', stats)
          : _.isNil(total)
          ? null
          : I18n.t('items.total', stats)
      }
    />
  )
}

const LogViewLayout = ({view, setView}) => {
  const changeView = useCallback(
    currentView => {
      if (currentView === view) return
      setView(view === 'table' ? 'list' : 'table')
    },
    [setView, view]
  )

  return (
    <Layout css={{gap: 20}} className="device-elk-log-view-change-layout">
      <Tooltip content={I18n.t('device.elk_log_view.table')}>
        <IconButton
          Icon={MiscApps}
          className="table-view-button"
          css={view === 'table' ? selectedIconStyle : {}}
          onClick={() => changeView('table')}
        />
      </Tooltip>
      <Tooltip content={I18n.t('device.elk_log_view.list')}>
        <IconButton
          Icon={RichEditorUnorderedList}
          className="list-view-button"
          css={view === 'list' ? selectedIconStyle : {}}
          onClick={() => changeView('list')}
        />
      </Tooltip>
    </Layout>
  )
}

const DeviceElkLogsTable = ({logs = []}) => (
  <Table className="device-elk-logs-table" css={logTableStyle}>
    <thead>
      <tr>
        <th css={{minWidth: 120}}>
          {I18n.t('device.elk_log_table.log_level')}
        </th>
        <th css={{minWidth: 120}}>{I18n.t('device.elk_log_table.service')}</th>
        <th css={{minWidth: 180}}>{I18n.t('device.elk_log_table.time')}</th>
        <th>{I18n.t('device.elk_log_table.raw_message')}</th>
      </tr>
    </thead>
    <tbody>
      {logs.map(({log_level, service, time, raw_message}, index) => (
        <tr className="device-elk-logs-row" key={`${time}-${index}`}>
          <td className="log_level">{log_level}</td>
          <td className="service">{service}</td>
          <td className="time">
            {parseAndFormatDate(time, 'yyyy-MM-dd h:mmaaa')}
          </td>
          <td className="raw_message">{raw_message}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)

const DeviceElkLogDownloadDetails = ({logDetails, isPending}) => {
  const [view, setView] = useState('table')

  const {logs, pagination} = logDetails.value()

  return (
    <>
      <Layout
        className="device-elk-logs-view"
        horizontal
        css={{justifyContent: 'space-between'}}
      >
        <LogViewLayout view={view} setView={setView} />
        <Pager isPending={isPending} pagination={pagination} />
      </Layout>
      {_.size(logs) > 0 ? (
        <>
          {view === 'table' ? (
            <DeviceElkLogsTable logs={logs} />
          ) : (
            <TupleItem className="device-elk-logId-details-item" value={logs} />
          )}
        </>
      ) : (
        <Fallback
          className="device-elk-logs-results-empty"
          message={I18n.t('device.elk_log_view.empty.query')}
        />
      )}
    </>
  )
}

export default DeviceElkLogDownloadDetails
