import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import NetworkErrorBoundary from '../../errors/network-error-boundary'
import Fallback from '../../errors/fallback'
import {readDeviceElkLogId} from '../device-service'
import DeviceElkLogId from './device-elk-log-id'

const DeviceElkLogDetails = () => {
  const {deviceGuid} = useParams()
  const [logIdDetails, isPending, startReadLogId] =
    useResource(readDeviceElkLogId)

  useEffect(() => {
    startReadLogId({deviceGuid})
  }, [startReadLogId, deviceGuid])

  return logIdDetails ? (
    <NetworkErrorBoundary
      fallbackRender={({error}) => (
        <Fallback
          className="device-elk-details-results-error"
          message={
            error.body?.message ||
            error.body?.content ||
            I18n.t('errors.service.devices')
          }
        />
      )}
    >
      <Suspense
        fallback={<Fallback className="device-elk-details-pending" spinner />}
      >
        <DeviceElkLogId logIdDetails={logIdDetails} isPending={isPending} />
      </Suspense>
    </NetworkErrorBoundary>
  ) : null
}

export default DeviceElkLogDetails
