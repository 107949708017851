import React from 'react'
import {
  Layout,
  SecondaryButton,
  useNotifications,
  useLoading,
  useModal,
} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import VPNProvider from './vpn-provider'
import Certificate from './vpn-certificate'
import VPNProfileModal from './vpn-profile-modal'
import {containerStyle} from './container-style'

/**
 * User actions rows which have buttons for
 *  1. Get VPN Profile Notification
 *  2. Refresh VPN Profile
 */
const VPNProfileActions = ({actions}) => {
  const {notifySuccess} = useNotifications()
  const [showVpnProfileModal, vpnProfileModal] = useModal(VPNProfileModal)

  return (
    <Layout
      className="vpn-actions"
      css={{gap: 20, margin: '10px 0'}}
      horizontal
    >
      {_.map(
        actions,
        action =>
          _.includes(['refresh', 'notify'], action.rel) && (
            <SecondaryButton
              key={action.rel}
              className={`${action.rel}-button`}
              css={{minWidth: 120}}
              onClick={async () => {
                if (await showVpnProfileModal({link: action}))
                  notifySuccess(
                    I18n.t(`device.vpn.actions.modal.${action.rel}.success`, {
                      getVPNProfile: 'get VPN profile',
                    })
                  )
              }}
            >
              {I18n.t(`device.vpn.actions.${action.rel}`)}
            </SecondaryButton>
          )
      )}
      {vpnProfileModal}
    </Layout>
  )
}

const DeviceVPNDetails = ({deviceVPN, isPending}) => {
  const vpnProfileData = deviceVPN.value()
  const {elementRef} = useLoading(isPending)
  const {providerName, links, certificate} = vpnProfileData || {}

  return (
    <div ref={elementRef} css={containerStyle}>
      {!_.isEmpty(vpnProfileData) ? (
        <Layout className="device-vpn-layout" vertical>
          {!_.isEmpty(links) && <VPNProfileActions actions={links} />}
          {providerName && (
            <TupleItem
              className="device-vpn-details"
              name={I18n.t('device.vpn.provider.provider_name')}
              value={providerName}
            />
          )}
          {certificate && <Certificate certificate={certificate} />}
          {links && (
            <TupleItem
              className="device-vpn-details"
              name="Provider"
              value={
                <VPNProvider
                  url={_.head(_.filter(links, {rel: 'provider'}))?.href}
                />
              }
            />
          )}
        </Layout>
      ) : (
        <Fallback
          className="device-vpn-results-empty"
          message={I18n.t('device.vpn.empty.query')}
        />
      )}
    </div>
  )
}

export default DeviceVPNDetails
