import React, {useRef} from 'react'
import {Layout, SelectCopy, Textarea, SmallOutlineButton} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'

const VPNProviderDetails = ({vpnProvider}) => {
  const vpnProviderData = vpnProvider.value()
  const textElementRef = useRef(null)

  const CopyCertificate = () => (
    <SelectCopy textElement={textElementRef.current} timeout={800}>
      {(copyHandler, selectSuccess) => (
        <SmallOutlineButton onClick={copyHandler} disabled={selectSuccess}>
          {selectSuccess
            ? I18n.t('device.vpn.certificate.certificate_copied')
            : I18n.t('device.vpn.certificate.copy_certificate')}
        </SmallOutlineButton>
      )}
    </SelectCopy>
  )
  return (
    <Layout className="vpn-provider-layout" vertical>
      {!_.isEmpty(vpnProviderData) ? (
        _.map(vpnProviderData, (value, key) => (
          <TupleItem
            className="vpn-provider"
            key={key}
            name={key}
            value={
              key !== 'peerCaCertificates' ? (
                value
              ) : (
                <Layout horizontal css={{gap: 20}}>
                  <Textarea
                    defaultValue={key}
                    rows="1"
                    cols="30"
                    ref={textElementRef}
                  />
                  <CopyCertificate />
                </Layout>
              )
            }
          />
        ))
      ) : (
        <Fallback
          className="vpn-provider-results-empty"
          message={I18n.t('device.vpn.provider.empty.query')}
        />
      )}
    </Layout>
  )
}

export default VPNProviderDetails
