import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readMissingDeviceScreamInfo} from './device-service'
import ScreamDetails from './scream-details'

const Scream = ({className, ...props}) => {
  const {deviceGuid} = useParams()
  const [scream, isPending, startReadDeviceScream] = useResource(
    readMissingDeviceScreamInfo
  )

  useEffect(() => {
    startReadDeviceScream({deviceGuid})
  }, [startReadDeviceScream, deviceGuid])

  return (
    <div className={classes('device-scream', className)} {...props}>
      {scream ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="device-scream-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={
              <Fallback className="device-scream-result-pending" spinner />
            }
          >
            <ScreamDetails scream={scream} isPending={isPending} />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default Scream
