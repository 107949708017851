import {Routes, Route} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import FullScreenErrorMessage from './components/errors/fullscreen-error-message'
import AccountDetailsPage from './components/account-details/account-details-page'
import AccountPage from './components/account/account-page'
import DeviceSlidePanel from './components/account-details/device-slide-panel'
import AppLayout from './app-layout'
import DevicePage from './components/device/device-page'
import DeviceElkLog from './components/device/elk-log/device-elk-log'

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<AppLayout />}>
      <Route
        path="account/:accountGuid/device/:deviceGuid"
        element={<DevicePage />}
      />
      <Route
        path="device/device-elk-log/:deviceGuid"
        element={<DeviceElkLog />}
      />
      <Route index element={<AccountPage />} />
      <Route path="account/:accountGuid" element={<AccountDetailsPage />}>
        <Route path=":deviceGuid" element={<DeviceSlidePanel />} />
      </Route>
      <Route
        path="*"
        element={
          <FullScreenErrorMessage
            className="not-found-error"
            message={I18n.t('errors.not_found.message')}
          />
        }
      />
    </Route>
    <Route
      path="/unauthorized"
      element={
        <>
          <Helmet>
            <title>{I18n.t('unauth.title')}</title>
          </Helmet>
          <FullScreenErrorMessage
            className="unauthorized-page"
            message={I18n.t('unauth.title')}
            description={I18n.t('unauth.details')}
          />
        </>
      }
    />
  </Routes>
)

export default AppRoutes
