import {Suspense, useRef, useState, useMemo, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useResource} from '@lookout/suspense'
import {colors, Layout} from '@lookout/ui'
import {ErrorBoundary} from 'react-error-boundary'
import searchIcon from '../../assets/images/search.svg'
import {toPlainObject} from '../../lib/utils/query-params'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readAccounts} from './account-service'
import AccountResults from './account-results'
import AccountSearch from './account-search'

const SearchPlaceholder = () => (
  <Layout
    className="accounts-results-initial"
    css={{
      height: '60vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    horizontal
  >
    <img
      src={searchIcon}
      alt={I18n.t('account.search.label')}
      css={{
        height: 80,
        maxWidth: '70%',
      }}
    />
    <div css={{color: colors.black300, marginTop: 20}}>
      {I18n.t('account.search.initial')}
    </div>
  </Layout>
)

const AccountPage = () => {
  const [searchParams] = useSearchParams()
  const query = useMemo(() => toPlainObject(searchParams), [searchParams])

  const [accounts, isPending, startReadAccounts] = useResource(readAccounts)
  // keeping a ref to the last issued resource for a way to dispose it without
  // re-initiating an effect
  const previousAccountsRef = useRef()
  previousAccountsRef.current = accounts

  const [initialQuery] = useState(query)
  useEffect(() => {
    if (initialQuery !== query || !_.isEmpty(query))
      if (_.isEmpty(query)) previousAccountsRef.current.dispose()
      else startReadAccounts({query})
  }, [startReadAccounts, query, initialQuery])

  return (
    <div className="account-page" css={{padding: '40px'}}>
      <AccountSearch />
      {accounts ? (
        <ErrorBoundary
          fallback={
            <Fallback
              className="accounts-unhandled-error"
              message={I18n.t('errors.generic.message')}
            />
          }
        >
          <NetworkErrorBoundary
            fallback={
              <Fallback
                className="accounts-results-error"
                message={I18n.t('errors.service.accounts')}
              />
            }
          >
            <Suspense
              fallback={
                <Fallback className="accounts-results-pending" spinner />
              }
            >
              <AccountResults accounts={accounts} isPending={isPending} />
            </Suspense>
          </NetworkErrorBoundary>
        </ErrorBoundary>
      ) : (
        <SearchPlaceholder />
      )}
    </div>
  )
}

export default AccountPage
