import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readMissingDeviceWipeInfo} from './device-service'
import WipeDetails from './wipe-details'

const Wipe = ({className, ...props}) => {
  const {deviceGuid} = useParams()
  const [wipe, isPending, startReadDeviceWipeInfo] = useResource(
    readMissingDeviceWipeInfo
  )

  useEffect(() => {
    startReadDeviceWipeInfo({deviceGuid})
  }, [startReadDeviceWipeInfo, deviceGuid])

  return (
    <div className={classes('device-wipe', className)} {...props}>
      {wipe ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="device-wipe-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={
              <Fallback className="device-wipe-result-pending" spinner />
            }
          >
            <WipeDetails wipe={wipe} isPending={isPending} />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default Wipe
