import React, {Suspense} from 'react'
import {useResource, ResourceSuccess} from '@lookout/suspense'
import {
  LinkButton,
  WarningButton,
  Modal,
  Layout,
  PanelHeader,
  PanelFooter,
  Panel,
  PanelDescription,
} from '@lookout/ui'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import classes from '../../lib/utils/classes'
import {notifyForCertificate} from './device-service'

const VPNProfileModal = ({link, onClosed}) => {
  const [notify, isPending, startNotifyForCert] =
    useResource(notifyForCertificate)
  const {rel, href} = link

  return (
    <Modal
      className={classes('vpm-profile-action-modal', _.kebabCase(rel))}
      dismissProps={{outsidePress: false}}
      onClosed={onClosed}
    >
      {({closeModal}) => (
        <Panel
          onSubmit={() => startNotifyForCert({url: href})}
          onClose={() => closeModal()}
        >
          {({notifyError, hideAllNotifications}) => (
            <>
              <PanelHeader>
                {I18n.t(`device.vpn.actions.modal.${rel}.title`)}
              </PanelHeader>
              <Layout vertical>
                {notify && (
                  <NetworkErrorBoundary
                    resetKeys={[notify]}
                    onError={() =>
                      notifyError(I18n.t('errors.service.devices'))
                    }
                  >
                    <Suspense>
                      <ResourceSuccess
                        resource={notify}
                        onSuccess={closeModal}
                      />
                    </Suspense>
                  </NetworkErrorBoundary>
                )}
                <PanelDescription className="description">
                  {I18n.t(`device.vpn.actions.modal.${rel}.description`, {
                    getVPNProfile: 'get VPN profile',
                  })}
                </PanelDescription>
                <PanelDescription className="confirmation-msg">
                  {I18n.t(`device.vpn.actions.modal.${rel}.confirmation`)}
                </PanelDescription>
              </Layout>
              <PanelFooter>
                <LinkButton type="reset" className="cancel-button" autoFocus>
                  {I18n.t('buttons.cancel')}
                </LinkButton>
                <WarningButton
                  className="confirm-button"
                  type="submit"
                  disabled={isPending}
                  onClick={() => hideAllNotifications()}
                >
                  {isPending ? I18n.t('buttons.saving') : I18n.t('buttons.ok')}
                </WarningButton>
              </PanelFooter>
            </>
          )}
        </Panel>
      )}
    </Modal>
  )
}

export default VPNProfileModal
