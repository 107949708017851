import {useMemo, Suspense} from 'react'
import {useMatch, useNavigate, useParams, useHref} from 'react-router-dom'
import {
  RiskMarker,
  Table,
  IconButton,
  Tooltip,
  TableBulkSelect,
  OutlineButton,
  useTableBulkSelect,
  useNotifications,
  TableBulkSelectCheckboxCell,
  TableBulkSelectHeader,
  tableStyles,
  Layout,
  typography,
  colors,
  useModal,
} from '@lookout/ui'
import {MiscNewTab} from '@lookout/dingbats'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import classes from '../../lib/utils/classes'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import DeviceDisableModal from './device-disable-modal'
import AddDevicesVpnProfileModal from './add-devices-vpn-profile-modal'
import {readAccount} from './account-details-service'

const {blush} = typography

const TableActions = ({onChangeSuccess, accountInfo, devices}) => {
  const {selectedIds} = useTableBulkSelect()
  const {notifySuccess} = useNotifications()
  const [showAddDevicesVpnProfileModal, addDevicesVpnProfileModal] = useModal(
    AddDevicesVpnProfileModal
  )
  const [showDeviceDisableModal, deviceDisableModal] =
    useModal(DeviceDisableModal)
  const accountCode = accountInfo?.value().info?.code

  /* Checking if the device is enabled or disabled.
  in case if a device is disabled the 'features' have
  string like 'AQ;xxxx'; it always starts with 'AQ' */
  const disableButtonDisabled = useMemo(
    () =>
      !_.size(selectedIds) ||
      !!_.find(
        devices,
        ([cdsDeviceInfo, registrarDeviceInfo]) =>
          // check 'features' of selected device only
          _.includes(selectedIds, cdsDeviceInfo.header.guid) &&
          registrarDeviceInfo.features?.indexOf('AQ') === 0
      ),
    [devices, selectedIds]
  )

  return (
    <Layout className="table-actions" css={{paddingLeft: 40}} horizontal>
      <OutlineButton
        className="disable-device-button"
        disabled={disableButtonDisabled}
        css={{marginRight: 5, ...typography.h4}}
        onClick={async () => {
          if (
            await showDeviceDisableModal({
              accountCode,
              deviceGuids: selectedIds,
            })
          ) {
            notifySuccess(
              I18n.t('account_details.devices.actions.disable_device.success')
            )
            onChangeSuccess()
          }
        }}
      >
        {I18n.t('account_details.devices.actions.disable_device.button')}
      </OutlineButton>
      {accountCode === 'att_smartnetwork' && (
        <OutlineButton
          className="add-vpn-profile-button"
          disabled={_.isEmpty(selectedIds)}
          css={{marginRight: 5, ...typography.h4}}
          onClick={async () => {
            if (
              await showAddDevicesVpnProfileModal({
                deviceGuids: selectedIds,
              })
            )
              notifySuccess(
                I18n.t(
                  'account_details.devices.actions.add_vpn_profile.success'
                )
              )
          }}
        >
          {I18n.t('account_details.devices.actions.add_vpn_profile.button')}
        </OutlineButton>
      )}
      {deviceDisableModal}
      {addDevicesVpnProfileModal}
    </Layout>
  )
}

const DeviceResultsRow = ({accountCode, ...device}) => {
  const {
    deviceGuid,
    deviceName,
    appVersion,
    deviceDisabled,
    lastConnected,
    celldata,
    client,
  } = device
  const {accountGuid} = useParams()
  const navigate = useNavigate()
  const resultPath = `/account/${accountGuid}/${deviceGuid}`
  const deviceDetailsPath = useHref(
    `/account/${accountGuid}/device/${deviceGuid}`
  )
  const matchResult = useMatch(`${resultPath}/*`)

  return useMemo(
    () => (
      <tr
        className={classes('devices-result-row', `device-${deviceGuid}`)}
        css={[
          {cursor: 'pointer'},
          matchResult && {
            backgroundColor: `${colors.lightGreen300} !important`,
          },
        ]}
        onClick={() => {
          navigate(resultPath)
        }}
      >
        <TableBulkSelectCheckboxCell rowId={deviceGuid} />
        <td className="device-name" css={blush}>
          {deviceName}
        </td>
        <td className="device-guid">{deviceGuid}</td>
        <td className="app-version" css={blush}>
          {appVersion}
        </td>
        <td className="state" css={blush}>
          <RiskMarker riskLevel={deviceDisabled ? 'high' : 'secure'} />
          <span className="state-label">
            {I18n.t(`device.state.${deviceDisabled ? 'disabled' : 'enabled'}`)}
          </span>
        </td>
        <td className="last-connected" css={blush}>
          {lastConnected}
        </td>
        <td className="phone-number" css={blush}>
          {accountCode === 'att_smartnetwork'
            ? celldata.ctn_id
            : celldata.phone_num}
        </td>
        <td className="preloaded" css={blush}>
          {_.capitalize(
            I18n.t('account_details.devices.preloaded_version', {
              preloaded: client.preload_version <= 0,
            })
          )}
        </td>
        <td className="open-new-page" css={{width: 60}}>
          <a
            className="device-link"
            href={deviceDetailsPath}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <Tooltip
              content={I18n.t('account_details.devices.actions.new_tab')}
            >
              <IconButton Icon={MiscNewTab} />
            </Tooltip>
          </a>
        </td>
      </tr>
    ),
    [
      deviceGuid,
      matchResult,
      appVersion,
      deviceName,
      lastConnected,
      client.preload_version,
      deviceDisabled,
      accountCode,
      celldata,
      deviceDetailsPath,
      navigate,
      resultPath,
    ]
  )
}

const DeviceResultsRows = ({accountInfo, orderedDevices}) => {
  const accountCode = accountInfo?.value().info?.code

  return _.map(orderedDevices, ({deviceGuid, ...device}) => (
    <DeviceResultsRow
      key={deviceGuid}
      accountCode={accountCode}
      {...{deviceGuid, ...device}}
    />
  ))
}

const DeviceCellDataHeader = ({accountInfo}) => {
  const accountCode = accountInfo?.value().info?.code

  return (
    <th>
      {I18n.t(
        `account_details.devices.table.${
          accountCode === 'att_smartnetwork' ? 'ctn_id' : 'phone_num'
        }`
      )}
    </th>
  )
}
const DevicesResults = ({devices, onChangeSuccess}) => {
  const {accountGuid} = useParams()
  const accountInfo = useResourceOnce(() => readAccount({guid: accountGuid}))

  const fulfilledDevices = _.map(
    _.filter(devices.value(), ['status', 'fulfilled']),
    'value'
  )

  const deviceGuids = useMemo(
    () =>
      _.map(fulfilledDevices, ([cdsDeviceInfo]) => cdsDeviceInfo.header.guid),
    [fulfilledDevices]
  )

  const orderedDevices = useMemo(
    () =>
      _.orderBy(
        _.map(fulfilledDevices, ([cdsDeviceInfo, registrarDeviceInfo]) => {
          const {features = ''} = registrarDeviceInfo || {}
          const {
            header = {},
            software = {},
            client = {},
            celldata = {},
            device_checkin = {},
          } = cdsDeviceInfo || {}
          /**
           * Checking if the device is enabled or disabled.
           * in case if a device is disabled the 'features' have
           * string like 'AQ;xxxx'; it always starts with 'AQ'
           * */
          const deviceDisabled = features.indexOf('AQ') === 0

          return {
            celldata,
            client,
            deviceGuid: header.guid,
            deviceName: software.device_name,
            appVersion: client.package_version,
            deviceDisabled,
            lastConnected: device_checkin.device_timestamp,
          }
        }),
        ['lastConnected'],
        ['desc']
      ),
    [fulfilledDevices]
  )

  return (
    <TableBulkSelect rowIds={deviceGuids}>
      <div className="devices-result-layout">
        {_.size(fulfilledDevices) > 0 ? (
          <>
            <div className="table-actions-container">
              <NetworkErrorBoundary fallback={<TableActions />}>
                <Suspense fallback={<TableActions />}>
                  <TableActions
                    accountInfo={accountInfo}
                    devices={fulfilledDevices}
                    onChangeSuccess={onChangeSuccess}
                  />
                </Suspense>
              </NetworkErrorBoundary>
            </div>
            <Table
              className="devices-result-table"
              css={[
                tableStyles.default,
                tableStyles.striped,
                tableStyles.hover,
                tableStyles.cursorPointer,
                tableLayoutStyles.fullWidth,
                {
                  marginBottom: 40,
                },
              ]}
            >
              <thead>
                <tr>
                  <TableBulkSelectHeader />
                  <th>{I18n.t('account_details.devices.table.device_name')}</th>
                  <th>{I18n.t('account_details.devices.table.guid')}</th>
                  <th>{I18n.t('account_details.devices.table.app_version')}</th>
                  <th>{I18n.t('account_details.devices.table.state')}</th>
                  <th>
                    {I18n.t('account_details.devices.table.last_connected')}
                  </th>
                  <NetworkErrorBoundary fallback={<DeviceCellDataHeader />}>
                    <Suspense fallback={<DeviceCellDataHeader />}>
                      <DeviceCellDataHeader accountInfo={accountInfo} />
                    </Suspense>
                  </NetworkErrorBoundary>
                  <th>{I18n.t('account_details.devices.table.preloaded')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <NetworkErrorBoundary
                  fallback={<DeviceResultsRows {...{orderedDevices}} />}
                >
                  <Suspense
                    fallback={<DeviceResultsRows {...{orderedDevices}} />}
                  >
                    <DeviceResultsRows {...{orderedDevices, accountInfo}} />
                  </Suspense>
                </NetworkErrorBoundary>
              </tbody>
            </Table>
          </>
        ) : (
          <Fallback
            className="devices-results-empty"
            message={I18n.t('account_details.devices.table.empty.query')}
          />
        )}
      </div>
    </TableBulkSelect>
  )
}

export default DevicesResults
