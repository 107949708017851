import {request as lookoutRequest} from '@lookout/request'
import handle401 from '../session/handle-401'

const request = async (url, opts) =>
  lookoutRequest(url, {
    onError: error => error.response.status === 401 && handle401(error.body),
    ...opts,
  })

export default request
