import {useMemo} from 'react'
import {useLoading} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

// https://source.corp.lookout.com/services/cwaproxy/blob/master/app/resource/locate/LocationType.java
const locationEventType = {
  0: 'unspecified',
  1: 'signal_flare',
  2: 'find_my_device',
  3: 'theft_alerts',
  4: 'daily_checkin',
}

const LocationLogDetails = ({log, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const logInfo = log.value()

  const locationLogs = useMemo(
    () =>
      _.map(logInfo, locationLog => ({
        ..._.omit(locationLog, ['lat', 'lng', 'alt']),
        locationType: locationEventType[locationLog.locationType],
      })),
    [logInfo]
  )

  return (
    <div
      className="location-log-details-layout"
      ref={elementRef}
      css={containerStyle}
    >
      {!_.isEmpty(locationLogs) ? (
        <>
          <TupleItem
            className="location-log-details-item"
            value={locationLogs}
          />
        </>
      ) : (
        <Fallback
          className="location-log-details-empty"
          message={I18n.t('device.log.empty.query')}
        />
      )}
    </div>
  )
}

export default LocationLogDetails
