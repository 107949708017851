import {Layout, Panel, Modal, PanelHeader} from '@lookout/ui'

const HelpOption = ({helpKey, value}) => (
  <div>
    <b>{helpKey}</b> - <span css={{marginLeft: 5}}>{value}</span>
  </div>
)

const HelpModal = ({onClosed}) => (
  <Modal className="help-modal" onClosed={onClosed}>
    {({closeModal}) => (
      <Panel onClose={closeModal}>
        <PanelHeader>{I18n.t('help')}</PanelHeader>
        <Layout horizontal css={{gap: 20}}>
          <Layout className="search-options" vertical css={{flex: 1}}>
            <h3>{I18n.t('help_modal.search_options')}</h3>
            <p>{I18n.t('help_modal.search_keys')}</p>
            <HelpOption
              helpKey={I18n.t('help_modal.ctn_id')}
              value={I18n.t('help_modal.lookout_att')}
            />
            <HelpOption
              helpKey={I18n.t('account.search.table.account_guid')}
              value={I18n.t('help_modal.search_value')}
            />
            <HelpOption
              helpKey={I18n.t('account.search.table.device_guid')}
              value={I18n.t('help_modal.search_value')}
            />
            <HelpOption
              helpKey={I18n.t('account.search.table.email')}
              value={I18n.t('help_modal.search_value')}
            />
          </Layout>
          <Layout className="keyboard-shortcuts" vertical css={{flex: 1}}>
            <h3>{I18n.t('help_modal.keyboard_shortcuts')}</h3>
            <HelpOption helpKey="/" value={I18n.t('help_modal.search_page')} />
            <HelpOption
              helpKey={I18n.t('help_modal.esc')}
              value={I18n.t('help_modal.close_modal')}
            />
          </Layout>
        </Layout>
      </Panel>
    )}
  </Modal>
)

export default HelpModal
