import request from '../../lib/api/request'
import apiUrlFor from '../../lib/utils/api-url-for'
import {stringifyQuery} from '../../lib/utils/query-params'
import {handle403} from '../errors/service-error-helper'
import {
  readDeviceDetails,
  readRegistrarDeviceInfo,
} from '../device/device-service'

export const readAccount = async ({guid, ...opts}) => {
  try {
    return await request(apiUrlFor(`/cashier/accounts/${guid}`), opts)
  } catch (error) {
    return handle403({error, response: []})
  }
}

export const readDeviceGuids = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(`/registrar/public/account?${stringifyQuery(query)}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: []})
  }
}

export const readDevices = async ({query, ...opts}) => {
  const deviceGuids = await readDeviceGuids({query, ...opts})
  return Promise.allSettled(
    deviceGuids.map(deviceGuid => readDeviceDetails({deviceGuid}))
  )
}

export const readFirstDevice = async ({query, ...opts}) => {
  const deviceGuids = await readDeviceGuids({query, ...opts})
  return readRegistrarDeviceInfo({deviceGuid: _.head(deviceGuids)})
}

export const readAccountEntitler = async ({guid, query, ...opts}) => {
  try {
    return await request(apiUrlFor(`/entitler?${stringifyQuery(query)}`), opts)
  } catch (error) {
    return handle403({error, response: {accountState: {}, features: []}})
  }
}

export const readDevice = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(`/registrar/public/device?${stringifyQuery(query)}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const updateDevice = ({accountGuid, deviceGuid, disabled, ...opts}) =>
  request(
    apiUrlFor(`/registrar/admin/device?${stringifyQuery({accountGuid})}`),
    {
      body: JSON.stringify({deviceGuid, disable: disabled}),
      method: 'PUT',
      ...opts,
    }
  )

export const updateDevices = async ({
  accountGuid,
  deviceGuids,
  disabled,
  ...opts
}) =>
  Promise.all(
    deviceGuids.map(deviceGuid =>
      updateDevice({accountGuid, deviceGuid, disabled, ...opts})
    )
  )

export const deleteDevice = ({deviceGuid, ...opts}) =>
  request(
    apiUrlFor(`/registrar/admin/device?${stringifyQuery({deviceGuid})}`),
    {
      method: 'DELETE',
      ...opts,
    }
  )

export const readStateHistory = async ({guid, query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/cashier/accounts/${guid}/state_histories?${stringifyQuery(query)}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: []})
  }
}

export const readUserInfo = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(`/idpro2/user_info?${stringifyQuery(query)}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: {userInfoData: []}})
  }
}

export const readBreaches = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(`/idpro2/breaches?${stringifyQuery(query)}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: {breaches: []}})
  }
}

export const deleteAccount = ({accountGuid, ...opts}) =>
  request(
    apiUrlFor(
      `/registrar/admin/personal/account?${stringifyQuery({accountGuid})}`
    ),
    {
      method: 'DELETE',
      ...opts,
    }
  )

export const readChangeLogs = async ({accountGuid, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/cwaproxy/api/public/v1/account/event/log?account_guid=${accountGuid}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const createVpnProfiles = async ({
  deviceGuids,
  accountGuid,
  ...opts
}) => {
  try {
    return await Promise.all(
      _.map(deviceGuids, deviceGuid =>
        request(apiUrlFor(`/vpnprofile/admin/devices/${deviceGuid}`), {
          method: 'PUT',
          body: JSON.stringify({
            accountGuid,
            providerName: 'att_smart_network',
          }),
          ...opts,
        })
      )
    )
  } catch (error) {
    return handle403({error, response: []})
  }
}
