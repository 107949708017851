import React, {useState} from 'react'
import {colors, Layout} from '@lookout/ui'
import {AtlasArrowThinUp, AtlasArrowThinDown} from '@lookout/dingbats'

const expandCollapseStyle = {
  fill: colors.blueGray300,
  width: 22,
  height: 'auto',
  flexGrow: 0,
}

const PanelGroup = ({title, expanded, children, ...props}) => {
  const [panelExpanded, setPanelExpanded] = useState(expanded)
  return (
    <div {...props}>
      <h2
        css={{
          padding: 0,
          margin: 0,
          borderTop: `1px solid ${colors.gray200}`,
        }}
      >
        <Layout
          className="group-title"
          css={{
            cursor: 'pointer',
            padding: '20px 40px',
            '&:hover': {
              color: colors.lookout,
              backgroundColor: colors.lightGreen300,
            },
            '&:focus': {
              '.action-icon': {fill: colors.lookout},
            },
            alignItems: 'center',
          }}
          horizontal
          role="button"
          tabIndex={0}
          onClick={() => setPanelExpanded(!panelExpanded)}
          onKeyPress={({key}) =>
            (key === 'Enter' || key === 'Space') &&
            setPanelExpanded(!panelExpanded)
          }
        >
          <span className="panel-title" css={{flexGrow: 1}}>
            {title}
          </span>
          {panelExpanded ? (
            <AtlasArrowThinUp
              className="action-icon collapse-panel"
              css={expandCollapseStyle}
            />
          ) : (
            <AtlasArrowThinDown
              className="action-icon expand-panel"
              css={expandCollapseStyle}
            />
          )}
        </Layout>
      </h2>
      {panelExpanded && children}
    </div>
  )
}

export default PanelGroup
