import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readMissingDeviceLockInfo} from './device-service'
import LockDetails from './lock-details'

const Lock = ({className, ...props}) => {
  const {deviceGuid} = useParams()
  const [lock, isPending, readDeviceLock] = useResource(
    readMissingDeviceLockInfo
  )

  useEffect(() => {
    readDeviceLock({deviceGuid})
  }, [readDeviceLock, deviceGuid])

  return (
    <div className={classes('device-lock', className)} {...props}>
      {lock ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="device-lock-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={
              <Fallback className="device-lock-result-pending" spinner />
            }
          >
            <LockDetails lock={lock} isPending={isPending} />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default Lock
