import React, {useState} from 'react'
import {Table, tableStyles} from '@lookout/ui'
import Fallback from '../errors/fallback'
import parseAndFormatDate from '../utils/format-date-time'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import classes from '../../lib/utils/classes'
import TupleItem from '../utils/tuple-item'

const UserDetailsRow = ({guid, user, isExpanded}) => {
  if (isExpanded)
    return (
      <tr className={classes(`user-details-row-${guid}`, 'expanded-row')}>
        <td colSpan={999}>
          {_.map(user, (value, key) => (
            <TupleItem
              className="user-details-row"
              key={key}
              name={key}
              value={value}
            />
          ))}
        </td>
      </tr>
    )
  return null
}

const UserInfoDetails = ({usersInfo}) => {
  const [expandedUser, setExpandedUser] = useState(null)
  const {userInfoData} = usersInfo.value()

  return (
    <div className="users-info-result">
      {!_.isEmpty(userInfoData) ? (
        <Table
          className="users-info-table"
          css={[
            tableStyles.striped,
            tableStyles.cursorPointer,
            tableLayoutStyles.fullWidth,
            tableLayoutStyles.expandable,
            {
              marginBottom: 40,
            },
          ]}
        >
          <thead>
            <tr>
              <th>{I18n.t('account_details.user_info.table.type')}</th>
              <th>{I18n.t('account_details.user_info.table.guid')}</th>
              <th>{I18n.t('account_details.user_info.table.creation_time')}</th>
              <th>{I18n.t('account_details.user_info.table.updated_time')}</th>
              <th>{I18n.t('account_details.user_info.table.state')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(userInfoData, user => {
              const isExpanded = user.guid === expandedUser
              return (
                <React.Fragment key={user.guid}>
                  <tr
                    className={classes(
                      'user-info-table-row',
                      `user-${user.guid}`,
                      'expandable',
                      isExpanded && 'expanded'
                    )}
                    onClick={() =>
                      setExpandedUser(isExpanded ? null : user.guid)
                    }
                  >
                    <td className="type">{user.userInfoType}</td>
                    <td className="guid">{user.guid}</td>
                    <td className="creationTime">
                      {parseAndFormatDate(
                        user.creationTime,
                        'yyyy-MM-dd h:mmaaa'
                      )}
                    </td>
                    <td className="updatedTime">
                      {parseAndFormatDate(
                        user.updatedTime,
                        'yyyy-MM-dd h:mmaaa'
                      )}
                    </td>
                    <td className="state">{user.state}</td>
                  </tr>
                  <UserDetailsRow
                    guid={user.guid}
                    user={user}
                    isExpanded={isExpanded}
                  />
                </React.Fragment>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <Fallback
          className="users-info-results-empty"
          message={I18n.t('account_details.user_info.table.empty.query')}
        />
      )}
    </div>
  )
}

export default UserInfoDetails
