import {
  Layout,
  Button,
  LinkButton,
  colors,
  useNotifications,
  useModal,
} from '@lookout/ui'
import {useParams, useNavigate} from 'react-router-dom'
import formatDate from '../../lib/layout/format-date'
import TupleItem from '../utils/tuple-item'
import AccountDeleteModal from './account-delete-modal'

const formatYMDHMDate = dt => {
  if (!dt) return null
  if (_.isDate(dt)) return formatDate(dt)

  return formatDate(dt, 'yyyy-MM-dd h:mmaaa')
}

const SummaryResult = ({accountInfo, firstDeviceInfo}) => {
  const {accountGuid} = useParams()
  const [showAccountDeleteModal, accountDeleteModal] =
    useModal(AccountDeleteModal)

  const {guid, info, state, created_at, updated_at, product} =
    accountInfo.value()
  const {email} = firstDeviceInfo.value() || {}
  const {
    category,
    class: stateClass,
    sku,
    mechanism,
    state_expiry: expiresOn,
  } = state || {}
  const {
    account_type: accountType,
    code,
    externalid: externalId,
    tags,
    one_app_user,
  } = info || {}
  const navigate = useNavigate()
  const {notifySuccess} = useNotifications()

  return (
    <div className="summary-section">
      <Layout
        css={{alignItems: 'center', justifyContent: 'space-between'}}
        horizontal
      >
        <h1 className="account-email" css={{marginTop: 0}}>
          {email || I18n.t('account_details.summary.title')}
        </h1>
        <Layout className="account-info-controls" css={{gap: 10}} horizontal>
          <Button
            as="a"
            className="view-on-cashier-link"
            href={`${global.config.api.cashier_admin_page}/${accountGuid}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {I18n.t('account_details.summary.view_on_cashier')}
          </Button>
          <LinkButton
            className="delete-account"
            css={{color: colors.danger}}
            onClick={async () => {
              if (await showAccountDeleteModal({accountGuid})) {
                notifySuccess(
                  I18n.t('account_details.summary.delete_account.success')
                )
                navigate('/')
              }
            }}
          >
            {I18n.t('account_details.summary.delete_account.button')}
          </LinkButton>
        </Layout>
      </Layout>
      <TupleItem
        className="account-info"
        value={{
          product,
          account_type: accountType,
          user_guid: guid,
          category,
          code,
          external_id: externalId,
          state: stateClass,
          mechanism,
          sku,
          expires_on: formatYMDHMDate(expiresOn),
          created_at: formatYMDHMDate(created_at),
          updated_at: formatYMDHMDate(updated_at),
          tags: tags?.join(', '),
          one_app_user: one_app_user?.toString(), // its a boolean value true/false
        }}
      />
      {accountDeleteModal}
    </div>
  )
}

export default SummaryResult
