import {Table, tableStyles, typography} from '@lookout/ui'
import classes from '../../lib/utils/classes'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import Fallback from '../errors/fallback'

const {blush} = typography
const DeviceEventLogsTable = ({deviceEventLogs}) => {
  const {logs} = deviceEventLogs.value()

  return _.size(logs) > 0 ? (
    <Table
      className="device-event-logs-table"
      css={[tableStyles.striped, tableLayoutStyles.fullWidth, {marginTop: 10}]}
    >
      <thead>
        <tr>
          <th>{I18n.t('device.event_log.event_log_table.event')}</th>
          <th>{I18n.t('device.event_log.event_log_table.description')}</th>
          <th>{I18n.t('device.event_log.event_log_table.created_at')}</th>
        </tr>
      </thead>
      <tbody>
        {logs.map(({event, created_at, description}) => (
          <tr
            className={classes('device-event-log-row', _.toLower(event))}
            key={_.uniqueId('device-event-log')}
          >
            <td className="event">{event}</td>
            <td className="description" css={blush}>
              {description}
            </td>
            <td className="created-at" css={blush}>
              {created_at}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <Fallback
      className="device-event-logs-results-empty"
      message={I18n.t('device.event_log.empty.query')}
    />
  )
}

export default DeviceEventLogsTable
