import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {
  LinkButton,
  Modal,
  WarningButton,
  PanelDescription,
  PanelHeader,
  PanelFooter,
  Panel,
} from '@lookout/ui'
import {useResource, ResourceSuccess} from '@lookout/suspense'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {updateDevices} from './account-details-service'

const DeviceDisableModal = ({accountCode, deviceGuids, onClosed}) => {
  const [disableResource, isPending, startUpdateDevices] =
    useResource(updateDevices)
  const {accountGuid} = useParams()

  return (
    <Modal className="device-disable-modal" onClosed={onClosed}>
      {({closeModal}) => (
        <Panel
          onClose={() => closeModal()}
          onSubmit={() =>
            startUpdateDevices({accountGuid, deviceGuids, disabled: true})
          }
        >
          {({notifyError, hideAllNotifications}) => (
            <>
              {disableResource && (
                <NetworkErrorBoundary
                  resetKeys={[disableResource]}
                  onError={() => notifyError(I18n.t('errors.service.devices'))}
                >
                  <Suspense>
                    <ResourceSuccess
                      resource={disableResource}
                      onSuccess={closeModal}
                    />
                  </Suspense>
                </NetworkErrorBoundary>
              )}
              <PanelHeader>
                {I18n.t(
                  'account_details.devices.actions.disable_device.modal.title'
                )}
              </PanelHeader>

              <PanelDescription>
                {accountCode === 'att_smartnetwork'
                  ? I18n.t(
                      'account_details.devices.actions.disable_device.modal.not_supported'
                    )
                  : I18n.t(
                      'account_details.devices.actions.disable_device.modal.description',
                      {deviceGuid: deviceGuids.join(', ')}
                    )}
              </PanelDescription>
              <PanelFooter>
                <LinkButton type="reset" className="cancel-button" autoFocus>
                  {I18n.t('buttons.cancel')}
                </LinkButton>
                {accountCode !== 'att_smartnetwork' && (
                  <WarningButton
                    className="confirm-button"
                    type="submit"
                    disabled={isPending}
                    onClick={() => hideAllNotifications()}
                  >
                    {isPending
                      ? I18n.t('buttons.disabling')
                      : I18n.t('buttons.disable')}
                  </WarningButton>
                )}
              </PanelFooter>
            </>
          )}
        </Panel>
      )}
    </Modal>
  )
}

export default DeviceDisableModal
