import {Outlet, useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {
  Notifications,
  useNotificationsState,
  NotificationsContext,
  Layout,
} from '@lookout/ui'
import {useEffect} from 'react'
import AppNav from './app-nav'

const NotificationsLayout = ({children}) => {
  const {notifications, context: notificationsContext} = useNotificationsState()

  return (
    <>
      <Notifications
        className="app-notifications"
        notifications={notifications}
        css={{position: 'sticky', top: 0, zIndex: 2}}
      />
      <NotificationsContext.Provider value={notificationsContext}>
        {children}
      </NotificationsContext.Provider>
    </>
  )
}

const AppLayout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const onKeyDown = event => {
      if (event.key === '/') {
        event.preventDefault()
        navigate('/')
      }
    }
    global.document.addEventListener('keydown', onKeyDown)
    return () => {
      global.document.removeEventListener('keydown', onKeyDown)
    }
  }, [navigate])

  return (
    <div className="app-layout">
      <Helmet>
        <title>{I18n.t('page.title')}</title>
      </Helmet>

      <Layout
        horizontal
        css={{
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <div css={{'> *': {minWidth: '100vw', position: 'fixed', zIndex: 1}}}>
          <AppNav />
        </div>

        <main
          css={{
            marginTop: 60,
            position: 'relative',
            width: '100vw',
          }}
        >
          <NotificationsLayout>
            <Outlet />
          </NotificationsLayout>
        </main>
      </Layout>
    </div>
  )
}

export default AppLayout
