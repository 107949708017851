export const guidPattern = '[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}'

const idRegExp = /[\d]+/g
const guidRegExp = new RegExp(guidPattern, 'g')

export const scrubSensitiveData = str =>
  _.isString(str)
    ? _.reduce(
        [guidRegExp, idRegExp],
        (result, re) => _.replace(result, re, '***'),
        str
      )
    : str

export const mapValuesDeep = (obj, fn) =>
  _.mapValues(obj, (val, key) =>
    _.isPlainObject(val)
      ? mapValuesDeep(val, fn)
      : _.isArray(val)
      ? _.map(val, v => (_.isPlainObject(v) ? mapValuesDeep(v, fn) : v))
      : fn(val, key, obj)
  )
