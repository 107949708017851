import {Suspense} from 'react'
import {useParams} from 'react-router-dom'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readBreaches} from './account-details-service'
import BreachDetails from './breach-details'

const Breaches = () => {
  const {accountGuid} = useParams()
  const breaches = useResourceOnce(() => readBreaches({query: {accountGuid}}))
  return (
    <div className="breaches-layout">
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="breaches-results-error"
            message={error.body?.message || I18n.t('errors.service.accounts')}
          />
        )}
      >
        <Suspense
          fallback={<Fallback className="breaches-results-pending" spinner />}
        >
          <BreachDetails breachesInfo={breaches} />
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default Breaches
