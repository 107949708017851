import {isNetworkError} from '@lookout/request'

export const handle403 = ({error, response}) => {
  if (
    isNetworkError(error) &&
    error.response.status === 403 &&
    _.includes(error.body.content, 'Tagged Affinity')
  )
    return Promise.resolve(response)
  throw error
}

export const handle404 = ({error, response}) => {
  if (isNetworkError(error) && error.response.status === 404)
    return Promise.resolve(response)
  throw error
}
