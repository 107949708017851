import {Suspense, useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {useResource} from '@lookout/suspense'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readDevices} from './account-details-service'
import DevicesResults from './devices-results'

const Devices = () => {
  const {accountGuid} = useParams()
  const [devices, , startReadDevices] = useResource(readDevices)

  useEffect(() => {
    startReadDevices({query: {accountGuid}})
  }, [accountGuid, startReadDevices])

  const onChangeSuccess = useCallback(() => {
    startReadDevices({query: {accountGuid}})
  }, [accountGuid, startReadDevices])

  return (
    <div className="devices-section">
      {devices ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="devices-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
          resetKeys={[devices]}
        >
          <Suspense
            fallback={<Fallback className="devices-results-pending" spinner />}
          >
            <DevicesResults
              {...{
                devices,
                onChangeSuccess,
              }}
            />
          </Suspense>
        </NetworkErrorBoundary>
      ) : (
        <Fallback
          className="devices-results-empty"
          message={I18n.t('account_details.devices.table.empty.query')}
        />
      )}
    </div>
  )
}

export default Devices
