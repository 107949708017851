import serverUrlFor from '../utils/server-url-for'
import {assignLocation} from '../utils/location-proxy'
import destinationParams from './destination-params'

const logout = () => {
  const params = destinationParams({
    withPath: true,
  })
  assignLocation(`${serverUrlFor('/a/sso')}?${params}`)
}

export default logout
