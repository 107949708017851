import {ErrorBoundary} from 'react-error-boundary'
import Fallback from '../errors/fallback'
import classes from '../../lib/utils/classes'
import Scream from './scream'
import Wipe from './wipe'
import Lock from './lock'
import LocationLog from './location-log'

const DeviceLocation = ({className, ...props}) => (
  <ErrorBoundary
    fallback={
      <Fallback
        className="device-unhandled-error"
        message={I18n.t('errors.generic.message')}
      />
    }
  >
    <div
      className={classes('device-location', className)}
      css={{paddingLeft: 40}}
      {...props}
    >
      <h3>{I18n.t('device.scream.title')}</h3>
      <Scream />
      <h3>{I18n.t('device.wipe.title')}</h3>
      <Wipe />
      <h3>{I18n.t('device.lock.title')}</h3>
      <Lock />
      <h3>{I18n.t('device.log.title')}</h3>
      <LocationLog />
    </div>
  </ErrorBoundary>
)

export default DeviceLocation
