import {Layout, useLoading} from '@lookout/ui'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'
import DeviceHeaderDetails from './device-header-details'

const DeviceDetails = ({device, isPending}) => {
  const [cdsDeviceInfo, registrarDeviceInfo] = device.value()
  const {elementRef} = useLoading(isPending)

  return (
    <>
      <DeviceHeaderDetails
        isPending={isPending}
        registrarDeviceInfo={registrarDeviceInfo}
        cdsDeviceInfo={cdsDeviceInfo}
        css={{marginLeft: 20, marginRight: 20}}
      />
      <div
        ref={elementRef}
        css={[{marginLeft: 20, marginRight: 20}, containerStyle]}
        className="device-details-layout"
      >
        {_.map(_.keys(cdsDeviceInfo), deviceAttrGrp => {
          const info = cdsDeviceInfo[deviceAttrGrp]
          return (
            !_.isEmpty(info) &&
            _.isPlainObject(info) &&
            deviceAttrGrp !== 'registrarDeviceInfo' && (
              <Layout vertical key={deviceAttrGrp} css={{marginBottom: 30}}>
                <h3
                  className={_.kebabCase(deviceAttrGrp)}
                  css={{padding: '7px 0'}}
                >
                  {deviceAttrGrp}
                </h3>
                {_.map(_.keys(info), (deviceAttrName, index) => (
                  <TupleItem
                    key={`${deviceAttrName}-${index}`}
                    className="device-props"
                    name={deviceAttrName}
                    value={info[deviceAttrName]}
                  />
                ))}
              </Layout>
            )
          )
        })}
      </div>
    </>
  )
}

export default DeviceDetails
