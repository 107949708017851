import React, {useMemo, useRef} from 'react'
import * as x509 from '@peculiar/x509'
import {Layout, SelectCopy, Textarea, SmallOutlineButton} from '@lookout/ui'
import formatDate from '../../lib/layout/format-date'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'

const Certificate = ({certificate}) => {
  const textElementRef = useRef(null)
  const cert = useMemo(
    () => new x509.X509Certificate(certificate),
    [certificate]
  )
  const parsedCert = {
    Serial: cert.serialNumber,
    'Signature algorithm': {
      name: cert.signatureAlgorithm?.name,
      hash: cert.signatureAlgorithm?.hash?.name,
    },
    Issuer: cert.issuer,
    notBefore: formatDate(cert.notBefore),
    notAfter: formatDate(cert.notAfter),
    Subject: cert.subject,
    PublicKey: {
      algorithm: cert.publicKey?.algorithm?.name,
      tag: cert.publicKey?.tag,
    },
  }

  if (_.isEmpty(cert))
    return (
      <Fallback
        className="device-vpn-certificate-empty"
        message={I18n.t('device.vpn.certificate.empty.query')}
      />
    )
  const CopyCertificate = () => (
    <SelectCopy textElement={textElementRef.current} timeout={800}>
      {(copyHandler, selectSuccess) => (
        <SmallOutlineButton onClick={copyHandler} disabled={selectSuccess}>
          {selectSuccess
            ? I18n.t('device.vpn.certificate.certificate_copied')
            : I18n.t('device.vpn.certificate.copy_certificate')}
        </SmallOutlineButton>
      )}
    </SelectCopy>
  )
  return (
    <>
      <TupleItem
        className="device-vpn-certificate-items"
        name={I18n.t('device.vpn.certificate.parsed_certificate')}
        value={parsedCert}
      />
      {certificate && (
        <TupleItem
          className="device-vpn-certificate-items"
          name={I18n.t('device.vpn.certificate.certificate')}
          value={
            <Layout horizontal css={{gap: 20}}>
              <Textarea
                defaultValue={certificate}
                rows="1"
                cols="30"
                ref={textElementRef}
              />
              <CopyCertificate />
            </Layout>
          }
        />
      )}
    </>
  )
}

export default Certificate
