import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readIDPRO} from './device-service'
import DeviceIDPRODetails from './device-idpro-details'

const DeviceIDPRO = ({className, ...props}) => {
  const {accountGuid} = useParams()
  const [idpro, isPending, startReadIdpro] = useResource(readIDPRO)

  useEffect(() => {
    startReadIdpro({accountGuid})
  }, [startReadIdpro, accountGuid])

  return (
    <div className={classes('device-idpro', className)} {...props}>
      {idpro ? (
        <NetworkErrorBoundary
          resetKeys={[accountGuid]}
          fallback={
            <Fallback
              className="device-idpro-results-error"
              message={I18n.t('errors.service.idpro')}
            />
          }
        >
          <Suspense
            fallback={
              <Fallback className="device-idpro-result--pending" spinner />
            }
          >
            <DeviceIDPRODetails idpro={idpro} isPending={isPending} />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default DeviceIDPRO
