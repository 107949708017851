import {Suspense} from 'react'
import {
  LinkButton,
  Modal,
  WarningButton,
  PanelDescription,
  PanelHeader,
  PanelFooter,
  Panel,
} from '@lookout/ui'
import {useResource, ResourceSuccess} from '@lookout/suspense'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {deleteAccount} from './account-details-service'

const AccountDeleteModal = ({accountGuid, onClosed}) => {
  const [deleteResource, isPending, startDeleteAccount] =
    useResource(deleteAccount)

  return (
    <Modal className="account-delete-modal" onClosed={onClosed}>
      {({closeModal}) => (
        <Panel
          onClose={() => closeModal()}
          onSubmit={() => startDeleteAccount({accountGuid})}
        >
          {({notifyError, hideAllNotifications}) => (
            <>
              {deleteResource && (
                <NetworkErrorBoundary
                  resetKeys={[deleteResource]}
                  onError={error =>
                    notifyError(
                      _.head(
                        // Error message contain "traceId" separated by semicolon
                        // Example "Account is not Lookout Personal account.; [traceId=60e90cfff8e81682949c9c62]"
                        _.split(error.body?.message || error.body?.content, ';')
                      ) || I18n.t('errors.service.accounts')
                    )
                  }
                >
                  <Suspense>
                    <ResourceSuccess
                      resource={deleteResource}
                      onSuccess={() => closeModal(true)}
                    />
                  </Suspense>
                </NetworkErrorBoundary>
              )}
              <PanelHeader>
                {I18n.t('account_details.summary.delete_account.modal.title')}
              </PanelHeader>
              <PanelDescription className="delete-account-modal-description">
                {I18n.t(
                  'account_details.summary.delete_account.modal.description'
                )}
              </PanelDescription>
              <PanelFooter>
                <LinkButton type="reset" className="cancel-button" autoFocus>
                  {I18n.t('buttons.cancel')}
                </LinkButton>
                <WarningButton
                  className="confirm-button"
                  type="submit"
                  disabled={isPending}
                  onClick={() => hideAllNotifications()}
                >
                  {isPending
                    ? I18n.t('buttons.deleting')
                    : I18n.t('buttons.delete')}
                </WarningButton>
              </PanelFooter>
            </>
          )}
        </Panel>
      )}
    </Modal>
  )
}

export default AccountDeleteModal
