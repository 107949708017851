import request from '../../lib/api/request'
import apiUrlFor from '../../lib/utils/api-url-for'
import {stringifyQuery} from '../../lib/utils/query-params'
import {handle403} from '../errors/service-error-helper'

export const readRegistrarDeviceInfo = async ({deviceGuid, ...opts}) => {
  try {
    return await request(
      apiUrlFor(`/registrar/public/device?${stringifyQuery({deviceGuid})}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readCDSDeviceInfo = async ({deviceGuid, ...opts}) => {
  try {
    return await request(apiUrlFor(`/consumer/devices/${deviceGuid}`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readDeviceDetails = async opts => {
  try {
    return Promise.all([readCDSDeviceInfo(opts), readRegistrarDeviceInfo(opts)])
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readVPNProfile = async ({deviceGuid, ...opts}) => {
  try {
    return await request(
      apiUrlFor(`/vpnprofile/admin/devices/${deviceGuid}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readVPNProvider = async ({url, ...opts}) => {
  try {
    return await request(apiUrlFor(`/vpnprofile${url}`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const notifyForCertificate = async ({url, ...opts}) => {
  try {
    return await request(apiUrlFor(`/vpnprofile/${url}`), {
      method: 'PUT',
      ...opts,
    })
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readIDPRO = async ({accountGuid, ...opts}) => {
  try {
    // TODO: will remove extra '/' from the URL after it fixes from the back-end
    return await request(
      apiUrlFor(`/idpro//?${stringifyQuery({accountGuid})}`),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readMicropush = async ({deviceGuid, ...opts}) => {
  try {
    return await request(apiUrlFor(`/micropush_cmd/${deviceGuid}`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readMicropushTokens = async ({deviceGuid, ...opts}) => {
  try {
    return request(apiUrlFor(`/pushcart/tokens/${deviceGuid}`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const locationHistory = async ({deviceGuid, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/device/locate/history?${stringifyQuery({deviceGuid})}&brief=false`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readMissingDeviceScreamInfo = async ({deviceGuid, ...opts}) => {
  try {
    return await request(apiUrlFor(`/missingdevice/${deviceGuid}/scream`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readMissingDeviceWipeInfo = async ({deviceGuid, ...opts}) => {
  try {
    return await request(apiUrlFor(`/missingdevice/${deviceGuid}/wipe`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readMissingDeviceLockInfo = async ({deviceGuid, ...opts}) => {
  try {
    return await request(apiUrlFor(`/missingdevice/${deviceGuid}/lock`), opts)
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readDeviceElkLogId = async ({deviceGuid, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/cwaproxy/api/public/v1/device/elk/log/trigger?device_guid=${deviceGuid}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readDeviceElkLogStatus = async ({logId, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/cwaproxy/api/public/v1/device/elk/log/status?log_id=${logId}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readDeviceElkLog = async ({query, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/cwaproxy/api/public/v1/device/elk/log?${stringifyQuery(query)}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const readDeviceEventLogs = async ({deviceGuid, ...opts}) => {
  try {
    return await request(
      apiUrlFor(
        `/cwaproxy/api/public/v1/device/event/log?device_guid=${deviceGuid}`
      ),
      opts
    )
  } catch (error) {
    return handle403({error, response: {}})
  }
}

export const sendDeviceLog = ({deviceGuid, ...opts}) =>
  request(
    apiUrlFor(
      `/cwaproxy/api/public/v1/deviceLog/sync?device_guid=${deviceGuid}`
    ),
    {
      method: 'POST',
      body: JSON.stringify({}),
      ...opts,
    }
  )
