import {Layout, Spinner} from '@lookout/ui'

const Fallback = ({className, message, spinner, css}) => (
  <Layout
    className={className}
    css={[
      {minHeight: '33vh', alignItems: 'center', justifyContent: 'center'},
      css,
    ]}
    horizontal
  >
    {spinner ? <Spinner size={10} /> : <p>{message}</p>}
  </Layout>
)

export default Fallback
