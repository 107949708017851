import React from 'react'
import {Layout} from '@lookout/ui'
import {Outlet} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import Fallback from '../errors/fallback'
import PanelGroup from '../../lib/layout/panel-group'
import Devices from './devices'
import StateHistory from './state-histories'
import AccountSummary from './summary'
import Entitlement from './entitlement'
import UserInfo from './user-info'
import Breaches from './breaches'
import ChangeLogs from './change-logs'

const deviceDetailSections = [
  'devices',
  'entitlement',
  'change_logs',
  'state_history',
  'user_info',
  'breaches',
]

const DeviceSection = ({section}) => (
  <PanelGroup
    expanded
    className={_.kebabCase(section)}
    title={I18n.t(`account_details.${section}.title`)}
    css={{marginLeft: -40, marginRight: -40}}
  >
    {section === 'devices' ? <Devices /> : null}
    {section === 'entitlement' ? <Entitlement /> : null}
    {section === 'change_logs' ? <ChangeLogs /> : null}
    {section === 'state_history' ? <StateHistory /> : null}
    {section === 'user_info' ? <UserInfo /> : null}
    {section === 'breaches' ? <Breaches /> : null}
  </PanelGroup>
)
const AccountDetailsPage = () => (
  <Layout
    className="account-details-page"
    css={{margin: '0 40px 50px 40px'}}
    vertical
  >
    <ErrorBoundary
      fallback={
        <Fallback
          className="account-details-unhandled-error"
          message={I18n.t('errors.generic.message')}
        />
      }
    >
      <AccountSummary />
      {deviceDetailSections.map((section, index) => (
        <DeviceSection key={index} section={section} />
      ))}
    </ErrorBoundary>
    <Outlet />
  </Layout>
)

export default AccountDetailsPage
