import {useLoading} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

const DeviceIDPRODetails = ({idpro, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const idproInfo = idpro.value()
  return (
    <div className="device-idpro-layout" css={containerStyle} ref={elementRef}>
      {!_.isEmpty(idproInfo) ? (
        _.map(idproInfo, (value, name) => (
          <TupleItem
            className="device-idpro-item"
            key={name}
            name={name}
            value={value}
          />
        ))
      ) : (
        <Fallback
          className="breach-results-empty"
          message={I18n.t('device.idpro.empty.query')}
        />
      )}
    </div>
  )
}

export default DeviceIDPRODetails
