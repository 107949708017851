import {Link} from 'react-router-dom'
import {colors, LinkButton, Layout, useModal} from '@lookout/ui'
import {MiscCircleQuestion} from '@lookout/dingbats'
import logoMark from './assets/images/logo-mark.svg'
import logoutIcon from './assets/images/logout.svg'
import adminIcon from './assets/images/admin.svg'
import logout from './lib/session/logout'
import HelpModal from './help-modal'

const AppNav = () => {
  const [showHelpModal, helpModal] = useModal(HelpModal)

  return (
    <nav className="app-nav" css={{background: colors.black200}}>
      <Layout
        css={{
          width: '100vw',
          height: 58,
          justifyContent: 'space-between',
        }}
        horizontal
      >
        <header
          className="app-nav-header"
          css={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Link
            className="logo-link"
            to="/"
            css={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 10,
            }}
          >
            <img
              src={logoMark}
              alt={I18n.t('brand.lookout')}
              css={{width: 45}}
            />

            <img
              className="admin-console-icon"
              alt={I18n.t('admin_console')}
              src={adminIcon}
              css={{marginLeft: 15, height: 14}}
            />
          </Link>
        </header>
        <Layout
          css={{
            marginRight: -15,
            justifyContent: 'center',
          }}
          horizontal
        >
          <LinkButton
            className="help-link"
            onClick={async () => {
              await showHelpModal()
            }}
            css={{
              display: 'flex',
              outline: 'none',
              justifyContent: 'center',
              width: '100%',
              paddingLeft: 5,
            }}
          >
            <MiscCircleQuestion
              className="lookout-info-tip-icon"
              css={{
                fill: colors.lightBlueGray300,
                width: 30,
                height: 30,
                '.ldb-circleq-q': {fill: colors.black200},
              }}
            />
          </LinkButton>
          <LinkButton
            className="logout-link"
            onClick={logout}
            css={{
              display: 'flex',
              justifyContent: 'center',
              borderLeft: `1px solid ${colors.blueGray100}`,
              width: '100%',
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 5,
            }}
          >
            <img
              src={logoutIcon}
              alt={I18n.t('logout')}
              css={{
                maxHeight: 30,
                width: 50,
              }}
            />
          </LinkButton>
        </Layout>
      </Layout>
      {helpModal}
    </nav>
  )
}

export default AppNav
