import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import PanelGroup from '../../lib/layout/panel-group'
import classes from '../../lib/utils/classes'
import DeviceActions from './device-actions'
import DeviceDetails from './device-details'
import DeviceVPN from './device-vpn'
import DeviceIDPRO from './device-idpro'
import DeviceMicropush from './device-micropush'
import DeviceLocation from './device-location'
import DeviceEventLogs from './device-event-logs'
import {readDeviceDetails} from './device-service'

// load device details when open device details in a tab
const DeviceSummary = () => {
  const {deviceGuid} = useParams()
  const [device, isPending, startReadDeviceDetails] =
    useResource(readDeviceDetails)

  useEffect(() => {
    startReadDeviceDetails({deviceGuid})
  }, [startReadDeviceDetails, deviceGuid])
  return (
    <>
      <DeviceActions newTabStyle={{paddingRight: 70, paddingTop: 10}} />
      {device && (
        <NetworkErrorBoundary
          resetKeys={[deviceGuid]}
          fallback={
            <Fallback
              className="device-info-results-error"
              message={I18n.t('errors.service.devices')}
            />
          }
        >
          <Suspense
            fallback={<Fallback className="device-info-pending" spinner />}
          >
            <DeviceDetails isPending={isPending} device={device} />
          </Suspense>
        </NetworkErrorBoundary>
      )}
    </>
  )
}

const DevicePage = ({className, device, isPending, ...props}) => (
  <div
    className={classes('device-page', className)}
    css={{paddingBottom: 40}}
    {...props}
  >
    <ErrorBoundary
      fallback={
        <Fallback
          className="device-unhandled-error"
          message={I18n.t('errors.generic.message')}
        />
      }
    >
      {device ? (
        <DeviceDetails device={device} isPending={isPending} />
      ) : (
        <DeviceSummary />
      )}
      <PanelGroup
        className="device-vpn-group"
        title={I18n.t('device.vpn.title')}
      >
        <DeviceVPN />
      </PanelGroup>
      <PanelGroup
        className="device-idpro-group"
        title={I18n.t('device.idpro.title')}
      >
        <DeviceIDPRO />
      </PanelGroup>
      <PanelGroup
        className="device-micropush-group"
        title={I18n.t('device.micropush.title')}
      >
        <DeviceMicropush />
      </PanelGroup>
      <PanelGroup
        className="device-location-group"
        title={I18n.t('device.location.title')}
      >
        <DeviceLocation />
      </PanelGroup>
      <PanelGroup
        className="device-event-logs-group"
        title={I18n.t('device.event_log.title')}
      >
        <DeviceEventLogs />
      </PanelGroup>
    </ErrorBoundary>
  </div>
)

export default DevicePage
