import {timings, tableFnStyles, tableStyleConstants} from '@lookout/ui'
import {colors, breakpoints} from '@lookout/design'
import {mobileContentPadding, contentPadding} from './responsive'

const {belowQuery, screenXsMax} = breakpoints

export const containerStyles = {
  fullWidth: {
    marginLeft: -contentPadding,
    marginRight: -contentPadding,

    [belowQuery({max: screenXsMax})]: {
      marginLeft: -mobileContentPadding,
      marginRight: -mobileContentPadding,
    },
  },
}

const cellPaddingStyle = {
  ':first-of-type': {
    paddingLeft: contentPadding,
  },
  ':last-of-type': {
    paddingRight: contentPadding,
  },
  [belowQuery({max: screenXsMax})]: {
    ':first-of-type': {
      paddingLeft: mobileContentPadding,
    },
    ':last-of-type': {
      paddingRight: mobileContentPadding,
    },
  },
}

export const tableLayoutStyles = {
  fullWidth: {
    width: '100%',
    marginBottom: contentPadding / 2,

    '> thead': {
      '> tr': {
        '> th': cellPaddingStyle,
      },
    },

    '> tbody': {
      '> tr': {
        transition: `opacity ${timings.briskly}`,

        '> td': cellPaddingStyle,
      },

      '&.loading.in': {
        '> tr': {
          opacity: 0.5,
          cursor: 'not-allowed',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  expandable: {
    '.expanded-row': {
      '> td:first-of-type': {
        borderLeft: `${tableStyleConstants.expandThumbWidth}px solid ${colors.lookout}`,
      },
    },
    ...tableFnStyles.expandable({
      cellHorizontalPadding: contentPadding,
      thumbStyle: {
        [belowQuery({max: screenXsMax})]: {
          paddingLeft:
            mobileContentPadding - tableStyleConstants.expandThumbWidth,
        },
      },
    }),
  },
  scrollable: {
    maxHeight: 450,
    overflow: 'auto',
    marginTop: 10,
    marginBottom: 20,
    thead: {
      th: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: colors.lightGray100,
      },
    },
  },
}
