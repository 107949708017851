import {Suspense} from 'react'
import {
  Tooltip,
  IconButton,
  Layout,
  SecondaryButton,
  useNotifications,
} from '@lookout/ui'
import {useResource, ResourceSuccess} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import {MiscArrowDownload, MiscFileMixed} from '@lookout/dingbats'
import apiUrlFor from '../../lib/utils/api-url-for'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {sendDeviceLog} from './device-service'

const DeviceActions = ({newTabStyle}) => {
  const {deviceGuid} = useParams()
  const [sendDeviceLogResource, isPending, startSendDeviceLog] =
    useResource(sendDeviceLog)
  const {notifySuccess, notifyError} = useNotifications()

  return (
    <Layout
      className="device-action-icons"
      css={[{alignItems: 'center', gap: 20}, newTabStyle]}
      horizontal
    >
      {sendDeviceLogResource && (
        <NetworkErrorBoundary
          resetKeys={[sendDeviceLogResource]}
          onError={error =>
            notifyError(error.body.error || I18n.t('errors.service.devices'))
          }
        >
          <Suspense>
            <ResourceSuccess
              resource={sendDeviceLogResource}
              onSuccess={() => notifySuccess(I18n.t('device.send_log.success'))}
            />
          </Suspense>
        </NetworkErrorBoundary>
      )}
      <text>{I18n.t('device.manifests')}</text>
      <Tooltip content={I18n.t('device.elk_log')}>
        <a
          className="download-elk-log"
          href={`/device/device-elk-log/${deviceGuid}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <IconButton Icon={MiscFileMixed} />
        </a>
      </Tooltip>
      <Tooltip content={I18n.t('device.download_log')}>
        <a
          className="download-device-log"
          href={apiUrlFor(
            `/cwaproxy/api/public/v1/deviceLog?device_guid=${deviceGuid}`
          )}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <IconButton Icon={MiscArrowDownload} />
        </a>
      </Tooltip>
      <SecondaryButton
        className="send-device-log-button"
        disabled={isPending}
        onClick={() => startSendDeviceLog({deviceGuid})}
      >
        {I18n.t('device.send_log.title')}
      </SecondaryButton>
    </Layout>
  )
}

export default DeviceActions
