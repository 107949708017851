import {useEffect, Suspense} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import NetworkErrorBoundary from '../../errors/network-error-boundary'
import Fallback from '../../errors/fallback'
import {readDeviceElkLogStatus} from '../device-service'
import DeviceElkLogPolling from './device-elk-log-polling'
import {setLogId} from './log-id-store'

const LogStatusSuccess = ({logStatus, children}) => {
  const result = logStatus.value()
  return result ? children : null
}

const DeviceElkLogId = ({logIdDetails}) => {
  const {deviceGuid} = useParams()
  const logId = logIdDetails.value().log_id
  const [logStatus, , startReadDeviceElkLogStatus] = useResource(
    readDeviceElkLogStatus
  )

  useEffect(() => {
    startReadDeviceElkLogStatus({logId})
    // store the logId to fetch the log faster on second time onwards
    // A logId is valid for 30 minutes
    setLogId({id: logId, deviceGuid})
  }, [deviceGuid, logId, startReadDeviceElkLogStatus])

  return (
    <div className="device-elk-log-id-container">
      {logStatus ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="device-elk-id-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={<Fallback className="device-elk-i-pending" spinner />}
          >
            <LogStatusSuccess logStatus={logStatus}>
              <DeviceElkLogPolling logId={logId} />
            </LogStatusSuccess>
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default DeviceElkLogId
