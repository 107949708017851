import {useEffect, Suspense} from 'react'
import {useNavigate} from 'react-router-dom'
import {tableStyles, useLoading, Table, typography} from '@lookout/ui'
import {useResourceOnce} from '@lookout/suspense'
import {
  tableLayoutStyles,
  containerStyles,
} from '../../lib/layout/layout-styles'
import classes from '../../lib/utils/classes'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readAccount} from '../account-details/account-details-service'
import Fallback from '../errors/fallback'

const {blush} = typography

const ProductColumn = ({accountInfo}) => {
  const {product} = accountInfo.value()

  return (
    <td className="product" css={blush}>
      {product}
    </td>
  )
}

const EmptyProductColumn = () => <td className="product" css={blush} />

const AccountResultsRow = ({account: {accountGuid, email}}) => {
  const navigate = useNavigate()
  const resultPath = `/account/${accountGuid}`
  const accountInfo = useResourceOnce(() => readAccount({guid: accountGuid}))

  return (
    <tr
      className={classes('account-result-row', _.kebabCase(accountGuid))}
      onClick={() => {
        navigate(resultPath)
      }}
      css={{cursor: 'pointer'}}
      key={accountGuid}
    >
      <td className="email" css={blush}>
        {email}
      </td>
      <NetworkErrorBoundary
        fallback={<EmptyProductColumn />}
        resetKeys={[accountInfo]}
      >
        <Suspense fallback={<EmptyProductColumn />}>
          <ProductColumn accountInfo={accountInfo} />
        </Suspense>
      </NetworkErrorBoundary>
      <td className="account-guid">{accountGuid}</td>
    </tr>
  )
}

const AccountResults = ({accounts, isPending}) => {
  const accountsValue = accounts.value()
  const navigate = useNavigate()
  const {elementRef} = useLoading(isPending)
  const uniqueAccounts = _.uniqBy(accountsValue, 'accountGuid')

  // Load account details page directly if there is only one account
  useEffect(() => {
    if (_.size(uniqueAccounts) === 1) {
      const [{accountGuid}] = uniqueAccounts
      navigate(`/account/${accountGuid}`)
    }
  }, [uniqueAccounts, navigate])

  return _.size(accountsValue) > 0 ? (
    <div css={[containerStyles.tableResponsive, containerStyles.fullWidth]}>
      <Table
        className="account-results-table"
        css={[
          tableStyles.striped,
          tableStyles.hover,
          tableStyles.loading,
          tableLayoutStyles.fullWidth,
        ]}
      >
        <thead>
          <tr>
            <th>{I18n.t('account.search.table.email')}</th>
            <th>{I18n.t('account.search.table.product')}</th>
            <th>{I18n.t('account.search.table.account_guid')}</th>
          </tr>
        </thead>
        <tbody ref={elementRef}>
          {_.map(uniqueAccounts, account => (
            <AccountResultsRow key={account.accountGuid} account={account} />
          ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <Fallback
      className="account-results-empty"
      message={I18n.t('account.search.table.empty.query')}
    />
  )
}

export default AccountResults
