import {useMemo} from 'react'
import {tableStyleConstants, useLoading} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

const PendingMessage = ({index, pushMessage}) => {
  const {id: messageId, timestamp} = pushMessage
  const {
    jti: messageIndex,
    iss: service,
    sub: feature,
    payload: message,
    aud: deviceGuid,
  } = pushMessage.payload || {}

  const formattedMessage = useMemo(
    () => ({
      messageId,
      timestamp,
      messageIndex,
      service,
      feature,
      deviceGuid: !_.isEmpty(deviceGuid) ? _.head(deviceGuid) : deviceGuid,
      message: _.isString(message) ? JSON.parse(message) : message,
    }),
    [deviceGuid, feature, message, messageId, messageIndex, service, timestamp]
  )

  return (
    <div
      css={{
        marginTop: 10,
        ':nth-of-type(odd)': {
          backgroundColor: tableStyleConstants.rowStripedBackgroundColor,
        },
      }}
      className="device-micropush-message"
    >
      <h3>
        {I18n.t('device.micropush.pending_micropush_message', {
          index: index + 1,
        })}
      </h3>
      <TupleItem className="device-micropush-item" value={formattedMessage} />
    </div>
  )
}

const DeviceMicropushDetails = ({microPush, microPushToken, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const pushMessages = microPush.value()
  const pushTokens = microPushToken.value()

  return (
    <div
      ref={elementRef}
      css={containerStyle}
      className="device-micropush-layout"
    >
      {!_.isEmpty(pushMessages) ? (
        <>
          <h4>{I18n.t('device.micropush.micropush_tokens')}</h4>
          <TupleItem className="device-micropush-tokens" value={pushTokens} />
          <h4>{I18n.t('device.micropush.pending_micropush')}</h4>
          {_.map(pushMessages, (pushMessage, index) => (
            <PendingMessage
              key={index}
              index={index}
              pushMessage={pushMessage}
            />
          ))}
        </>
      ) : (
        <Fallback
          className="micropush-results-empty"
          message={I18n.t('device.micropush.empty.query')}
        />
      )}
    </div>
  )
}

export default DeviceMicropushDetails
