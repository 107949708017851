import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {locationHistory} from './device-service'
import LocationLogDetails from './location-log-details'

const LocationLog = ({className, ...props}) => {
  const {deviceGuid} = useParams()
  const [log, isPending, startReadDeviceLog] = useResource(locationHistory)

  useEffect(() => {
    startReadDeviceLog({deviceGuid})
  }, [startReadDeviceLog, deviceGuid])

  return (
    <div className={classes('location-log', className)} {...props}>
      {log ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="location-log-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={
              <Fallback className="location-log-result-pending" spinner />
            }
          >
            <LocationLogDetails log={log} isPending={isPending} />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default LocationLog
