import serverUrlFor from '../utils/server-url-for'
import {assignLocation} from '../utils/location-proxy'
import {toAbsoluteUrl} from '../utils/absolute-url'
import destinationParams from './destination-params'

const handle401 = ({redirect_url: url} = {}) => {
  const params = destinationParams({
    withPath: true,
  })

  assignLocation(
    `${toAbsoluteUrl(serverUrlFor(url || '/a/sso'))}${
      params ? `?${params}` : ''
    }`
  )
}

export default handle401
