import {useLoading} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

const WipwDetails = ({wipe, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const wipeInfo = wipe.value()
  return (
    <div className="device-wipe-layout" ref={elementRef} css={containerStyle}>
      {!_.isEmpty(wipeInfo) ? (
        <>
          <TupleItem className="device-wipe-item" value={wipeInfo} />
        </>
      ) : (
        <Fallback
          className="device-wipe-empty"
          message={I18n.t('device.wipe.empty.query')}
        />
      )}
    </div>
  )
}

export default WipwDetails
