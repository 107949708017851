import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {Layout} from '@lookout/ui'
import Fallback from '../../errors/fallback'
import NetworkErrorBoundary from '../../errors/network-error-boundary'
import DeviceElkLogTitle from './device-elk-log-title'
import DeviceElkLogDownload from './device-elk-log-download'
import DeviceElkLogDetails from './device-elk-log-details'
import {getLogId} from './log-id-store'
import DeviceElkLogPolling from './device-elk-log-polling'

const DeviceElkLog = props => {
  const {deviceGuid} = useParams()
  const {logId, status: downloadStatus} = useMemo(
    () => getLogId({deviceGuid}),
    [deviceGuid]
  )

  return (
    <ErrorBoundary
      fallback={
        <Fallback
          className="device-elk-log-unhandled-error"
          message={I18n.t('errors.generic.message')}
        />
      }
    >
      <NetworkErrorBoundary
        fallbackRender={({error}) => (
          <Fallback
            className="device-elk-results-error"
            message={
              error.body?.message ||
              error.body?.content ||
              I18n.t('errors.service.devices')
            }
          />
        )}
      >
        <div className="device-elk-page" css={{marginBottom: 50}} {...props}>
          <Layout
            horizontal
            css={{gap: 30, alignItems: 'center', minHeight: 60}}
          >
            <DeviceElkLogTitle />
          </Layout>
          <Layout
            className="device-elk-logId-details-layout"
            css={{padding: '0 40px 20px'}}
          >
            {logId && _.lowerCase(downloadStatus) === 'completed' ? (
              <DeviceElkLogDownload logId={logId} />
            ) : logId && _.lowerCase(downloadStatus) !== 'completed' ? (
              <DeviceElkLogPolling logId={logId} />
            ) : (
              <DeviceElkLogDetails />
            )}
          </Layout>
        </div>
      </NetworkErrorBoundary>
    </ErrorBoundary>
  )
}
export default DeviceElkLog
