import {useLoading} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

const LockDetails = ({lock, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const lockInfo = lock.value()
  return (
    <div className="device-lock-layout" ref={elementRef} css={containerStyle}>
      {!_.isEmpty(lockInfo) ? (
        <>
          <TupleItem className="device-lock-item" value={lockInfo} />
        </>
      ) : (
        <Fallback
          className="device-lock-empty"
          message={I18n.t('device.lock.empty.query')}
        />
      )}
    </div>
  )
}

export default LockDetails
