import {breakpoints} from '@lookout/design'

export const contentPadding = 40
export const mobileContentPadding = 20

const {
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenLgMax,
  belowQuery,
} = breakpoints

export const maxima = {
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenLgMax,
}

export const mq = _.memoize(({xs, sm, md, lg, xl}) => ({
  ...(xl || lg || md || sm || xs),
  [belowQuery({max: screenLgMax})]: lg,
  [belowQuery({max: screenMdMax})]: md,
  [belowQuery({max: screenSmMax})]: sm,
  [belowQuery({max: screenXsMax})]: xs,
}))
