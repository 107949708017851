import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readMicropush, readMicropushTokens} from './device-service'
import DeviceMicropushDetails from './device-micropush-details'

const DeviceMicropush = ({className, ...props}) => {
  const {deviceGuid} = useParams()
  const [microPush, isPending, startReadMicropush] = useResource(readMicropush)
  const [microPushToken, isTokenPending, startReadMicropushTokens] =
    useResource(readMicropushTokens)

  useEffect(() => {
    startReadMicropush({deviceGuid})
    startReadMicropushTokens({deviceGuid})
  }, [startReadMicropush, startReadMicropushTokens, deviceGuid])

  return (
    <div className={classes('device-micropush', className)} {...props}>
      {microPush && microPushToken ? (
        <NetworkErrorBoundary
          fallback={
            <Fallback
              className="device-micropush-results-error"
              message={I18n.t('errors.service.devices')}
            />
          }
        >
          <Suspense
            fallback={
              <Fallback className="device-micropush-result-pending" spinner />
            }
          >
            <DeviceMicropushDetails
              microPush={microPush}
              microPushToken={microPushToken}
              isPending={isPending || isTokenPending}
            />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default DeviceMicropush
