import {useEffect, Suspense, useMemo, useCallback, useState} from 'react'
import {useResource} from '@lookout/suspense'
import {useLocation, useNavigate} from 'react-router-dom'
import {FilterSelect} from '@lookout/ui'
import Fallback from '../../errors/fallback'
import {stringifyQuery} from '../../../lib/utils/query-params'
import NetworkErrorBoundary from '../../errors/network-error-boundary'
import {readDeviceElkLog} from '../device-service'
import DeviceElkLogDownloadDetails, {
  defaultQuery,
} from './device-elk-log-download-details'

const useQuery = () => {
  const {search} = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const accountSearchFilterStyle = {
  dropdownIndicator: () => ({
    display: 'none',
  }),
}

const services = [
  {id: 'BREACH_REPORT', name: 'BREACH_REPORT'},
  {id: 'CASHIER', name: 'CASHIER'},
  {id: 'CONSUMER_THREAT_MGR', name: 'CONSUMER_THREAT_MGR'},
  {id: 'CWA_PROXY', name: 'CWA_PROXY'},
  {id: 'ENTITLER', name: 'ENTITLER'},
  {id: 'ENTITLER_DRIZZLE', name: 'ENTITLER_DRIZZLE'},
  {id: 'ID_PRO', name: 'ID_PRO'},
  {id: 'ID_PRO2', name: 'ID_PRO2'},
  {id: 'KEYMASTER', name: 'KEYMASTER'},
  {id: 'LOCATE', name: 'LOCATE'},
  {id: 'MACROPUSH', name: 'MACROPUSH'},
  {id: 'METRON', name: 'METRON'},
  {id: 'MICROPUSH', name: 'MICROPUSH'},
  {id: 'MISSING_DEVICE', name: 'MISSING_DEVICE'},
  {id: 'PARTNERNFN_DELIVERY', name: 'PARTNERNFN_DELIVERY'},
  {id: 'PUSH2APNS', name: 'PUSH2APNS'},
  {id: 'REGISTRAR', name: 'REGISTRAR'},
  {id: 'SAFE_BROWSING', name: 'SAFE_BROWSING'},
  {id: 'THEFT_ALERT', name: 'THEFT_ALERT'},
  {id: 'THEFT_ALERT_PHOTOS', name: 'THEFT_ALERT_PHOTOS'},
  {id: 'VPN_PROFILE', name: 'VPN_PROFILE'},
]

const FilterControl = ({setFilter}) => {
  const navigate = useNavigate()
  const groupedOptions = useMemo(
    () => [
      {
        id: 'services',
        name: 'Services',
        singular: true,
        options: services,
      },
      {
        id: 'level',
        name: 'Log Level',
        singular: true,
        options: [
          {id: 'info', name: I18n.t('device.elk_log_view.filter.level.info')},
          {id: 'debug', name: I18n.t('device.elk_log_view.filter.level.debug')},
          {id: 'trace', name: I18n.t('device.elk_log_view.filter.level.trace')},
        ],
      },
    ],
    []
  )

  const newOptionData = useMemo(
    () => ({
      groupId: 'message',
      groupName: 'Message',
      description: I18n.t('device.elk_log_view.filter.new_option'),
    }),
    []
  )

  const onFilterChange = useCallback(
    val => {
      navigate(`?${stringifyQuery({page: 1})}`)
      setFilter(FilterSelect.serialize(val, {groupedOptions}))
    },
    [groupedOptions, navigate, setFilter]
  )

  return (
    <div className="device-elk-filter">
      <FilterSelect
        autoFocus
        className="device-elk-filter-select"
        css={{marginBottom: 20}}
        placeholder={I18n.t('device.elk_log_view.filter.placeholder')}
        closeMenuOnSelect={false}
        defaultValue={undefined}
        hideSelectedOptions={false}
        isClearable
        isMulti
        isSearchable
        options={groupedOptions}
        controlShouldRenderValue
        getNewOptionData={() => newOptionData}
        onChange={onFilterChange}
        styles={accountSearchFilterStyle}
      />
    </div>
  )
}

const DeviceElkLogDownload = ({logId}) => {
  const [filter, setFilter] = useState({})
  const location = useLocation()
  const query = useQuery()
  const [deviceElkLog, isPending, startReadDeviceLog] =
    useResource(readDeviceElkLog)

  useEffect(() => {
    const queryPram = {
      log_id: logId,
      page: query.get('page') || defaultQuery.page,
      page_size: query.get('per_page') || defaultQuery.per_page,
      ...filter,
    }
    startReadDeviceLog({query: queryPram})
  }, [location.search, logId, query, startReadDeviceLog, filter])

  return (
    <>
      <FilterControl setFilter={setFilter} />
      {deviceElkLog ? (
        <NetworkErrorBoundary
          fallbackRender={({error}) => (
            <Fallback
              className="device-elk-log-download-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={
              <Fallback className="device-elk-log-download-pending" spinner />
            }
          >
            <DeviceElkLogDownloadDetails
              logDetails={deviceElkLog}
              isPending={isPending}
            />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </>
  )
}

export default DeviceElkLogDownload
