import React from 'react'
import {Layout, typography, tableStyleConstants} from '@lookout/ui'
import classes from '../../lib/utils/classes'

const {blush, breakText} = typography

const arrayValueStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}

const isElement = element => React.isValidElement(element)

const TupleItem = ({name, value, className}) => (
  <Layout
    className={classes(className, _.kebabCase(name))}
    css={{gap: 20, padding: '7px 0'}}
    horizontal
  >
    {name && (
      <div css={{minWidth: 200}} className="prop-name">
        {name}
      </div>
    )}
    <div
      css={[{flex: 1}, _.isArray(value) && arrayValueStyle]}
      className={
        isElement(value)
          ? 'prop-node'
          : _.isPlainObject(value)
          ? 'prop-object'
          : _.isArray(value)
          ? 'prop-array'
          : 'prop-value'
      }
    >
      {isElement(value) ? (
        value
      ) : _.isPlainObject(value) ? (
        _.map(value, (propValue, propName) => (
          <TupleItem
            className="object-tuple"
            key={propName}
            name={propName}
            value={propValue}
          />
        ))
      ) : _.isArray(value) && !_.isEmpty(value) ? (
        _.map(value, (arrayValue, index) => (
          <TupleItem
            className="array-tuple"
            key={index}
            value={arrayValue}
            css={{
              ':nth-of-type(odd)': {
                backgroundColor: tableStyleConstants.rowStripedBackgroundColor,
              },
            }}
          />
        ))
      ) : (
        <span css={[blush, breakText]} className={`${_.kebabCase(value)}`}>
          {_.toString(value)}
        </span>
      )}
    </div>
  </Layout>
)

export default TupleItem
