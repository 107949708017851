import {useLoading} from '@lookout/ui'
import Fallback from '../errors/fallback'
import TupleItem from '../utils/tuple-item'
import {containerStyle} from './container-style'

const ScreamDetails = ({scream, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const screamInfo = scream.value()
  return (
    <div className="device-scream-layout" ref={elementRef} css={containerStyle}>
      {!_.isEmpty(screamInfo) ? (
        <>
          <TupleItem className="device-scream-item" value={screamInfo} />
        </>
      ) : (
        <Fallback
          className="device-scream-empty"
          message={I18n.t('device.scream.empty.query')}
        />
      )}
    </div>
  )
}

export default ScreamDetails
