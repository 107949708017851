import {Suspense, useEffect} from 'react'
import {useResource} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import {useLoading} from '@lookout/ui'
import NetworkErrorBoundary from '../../errors/network-error-boundary'
import Fallback from '../../errors/fallback'
import {readCDSDeviceInfo} from '../device-service'

const DeviceTitle = ({device, isPending}) => {
  const {elementRef} = useLoading(isPending)
  const {software = {}, hardware = {}} = device.value()
  const deviceName = software.device_name || ''
  const deviceModel = hardware.model || ''
  const name = deviceName
    ? deviceName + (deviceModel !== deviceName ? ` (${deviceModel})` : '')
    : deviceModel

  return (
    <h2
      className="device-name"
      ref={elementRef}
      css={{paddingLeft: 40, margin: 0}}
    >
      {name || I18n.t('device.elk_log')}
    </h2>
  )
}

const DeviceElkLogTitle = () => {
  const [device, isPending, startReadDeviceInfo] =
    useResource(readCDSDeviceInfo)
  const {deviceGuid} = useParams()

  useEffect(() => {
    startReadDeviceInfo({deviceGuid})
  }, [startReadDeviceInfo, deviceGuid])

  return device ? (
    <NetworkErrorBoundary
      fallbackRender={({error}) => (
        <Fallback
          className="device-elk-log-title-error"
          css={{minHeight: 0, height: 60}}
          message={
            error.body?.message ||
            error.body?.content ||
            I18n.t('errors.service.devices')
          }
        />
      )}
    >
      <Suspense
        fallback={
          <Fallback
            className="device-elk-log-title-pending"
            spinner
            css={{minHeight: 0, height: 60}}
          />
        }
      >
        <DeviceTitle
          deviceGuid={deviceGuid}
          device={device}
          isPending={isPending}
        />
      </Suspense>
    </NetworkErrorBoundary>
  ) : null
}

export default DeviceElkLogTitle
