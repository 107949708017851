import {useEffect, Suspense} from 'react'
import {useResource, ResourceSuccess} from '@lookout/suspense'
import {useNavigate} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {FullScreenSpinner} from '@lookout/ui'
import FullScreenErrorMessage from './components/errors/fullscreen-error-message'
import NetworkErrorBoundary from './components/errors/network-error-boundary'
import AppRoutes from './app-routes'
import {preflightAuth} from './app-init-service'
import initSentry from './sentry-helper'

// this is a module variable to ensure it's only used once per
// app-invocation.
let checked = false

const AppInit = ({respectGlobalChecked = true}) => {
  const [preflightAuthResource, , startPreFlightAuth] =
    useResource(preflightAuth)
  const navigate = useNavigate()

  useEffect(() => initSentry(), [])

  useEffect(() => {
    // only call once, ever. until the user reloads (unless it is a unit test)
    if (!respectGlobalChecked || (!checked && !global.cypress)) {
      checked = true
      startPreFlightAuth()
    }
  }, [respectGlobalChecked, startPreFlightAuth])

  return (
    <ErrorBoundary
      fallbackRender={({resetErrorBoundary}) => (
        <FullScreenErrorMessage
          className="app-init-error"
          message={I18n.t('errors.generic.message')}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
    >
      {preflightAuthResource && (
        <NetworkErrorBoundary
          resetKeys={[preflightAuthResource]}
          fallback={<AppRoutes />}
          onError={({response: {status}}) =>
            status === 403 && navigate('/unauthorized')
          }
        >
          <Suspense
            fallback={<FullScreenSpinner className="app-init-loading" />}
          >
            <ResourceSuccess resource={preflightAuthResource} />
            <AppRoutes />
          </Suspense>
        </NetworkErrorBoundary>
      )}
    </ErrorBoundary>
  )
}

export default AppInit
