import {Table, tableStyles, typography} from '@lookout/ui'
import parseAndFormatDate from '../utils/format-date-time'
import {tableLayoutStyles} from '../../lib/layout/layout-styles'
import Fallback from '../errors/fallback'
import classes from '../../lib/utils/classes'

const {blush} = typography

const ChangeLogsTable = ({changeLogs}) => {
  const {logs} = changeLogs.value()

  return _.size(logs) > 0 ? (
    <Table
      className="change-logs-table"
      css={[tableStyles.striped, tableLayoutStyles.fullWidth, {marginTop: 10}]}
    >
      <thead>
        <tr>
          <th>{I18n.t('account_details.change_logs.table.event')}</th>
          <th>{I18n.t('account_details.change_logs.table.item')}</th>
          <th>{I18n.t('account_details.change_logs.table.description')}</th>
          <th>{I18n.t('account_details.change_logs.table.created_at')}</th>
        </tr>
      </thead>
      <tbody>
        {logs.map(({event, created_at, description, item}) => (
          <tr
            className={classes('change-log-row', _.toLower(`${event}-${item}`))}
            key={_.uniqueId('change-log')}
          >
            <td className="event">{event}</td>
            <td className="item" css={blush}>
              {item}
            </td>
            <td className="description" css={blush}>
              {description}
            </td>
            <td className="created-at" css={blush}>
              {parseAndFormatDate(created_at, 'yyyy-MM-dd h:mmaaa')}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <Fallback
      className="change-logs-results-empty"
      message={I18n.t('account_details.change_logs.table.empty.query')}
    />
  )
}

export default ChangeLogsTable
