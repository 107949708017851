import {Suspense, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useResource} from '@lookout/suspense'
import classes from '../../lib/utils/classes'
import Fallback from '../errors/fallback'
import NetworkErrorBoundary from '../errors/network-error-boundary'
import {readVPNProfile} from './device-service'
import DeviceVPNDetails from './device-vpn-details'

const DeviceVPN = ({className, ...props}) => {
  const {deviceGuid} = useParams()
  const [deviceVPN, isPending, startReadVPNProfile] =
    useResource(readVPNProfile)

  useEffect(() => {
    startReadVPNProfile({deviceGuid})
  }, [startReadVPNProfile, deviceGuid])

  return (
    <div className={classes('device-vpn-section', className)} {...props}>
      {deviceVPN ? (
        <NetworkErrorBoundary
          resetKeys={[deviceGuid]}
          fallbackRender={({error}) => (
            <Fallback
              className="device-vpn-results-error"
              message={
                error.body?.message ||
                error.body?.content ||
                I18n.t('errors.service.devices')
              }
            />
          )}
        >
          <Suspense
            fallback={
              <Fallback className="device-vpn-results-pending" spinner />
            }
          >
            <DeviceVPNDetails deviceVPN={deviceVPN} isPending={isPending} />
          </Suspense>
        </NetworkErrorBoundary>
      ) : null}
    </div>
  )
}

export default DeviceVPN
